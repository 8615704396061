import React, { useState, useContext, createContext, useEffect } from 'react';
import Auth from '../Utils/Auth';
import { useAddress } from './addressHook';

import getStateManager, { destroyState } from '../StateManager.js';

const authContext = createContext();

export function useAuth() {
  return useContext(authContext);
}

export function useProvideAuth() {
  console.log('[auth] useProvideAuth');

  let u = null;
  if (process.env.REACT_APP_SKIP_LOGIN === 'true') {
    u = 'local';
  }

  const [user, setUser] = useState(u);
  const address = useAddress();

  useEffect(() => {
    if (user === null) {
      // Send validation request, logging the user out if it fails.
      fetch(address.api_url('/api/username')).then((response) => {
        if (response.ok) {
          response.text().then((text) => setUser(text));
        }
      });
    }
  }, [address]);

  const signin = (username, password, cb) => {
    if (process.env.REACT_APP_SKIP_LOGIN === 'true') {
      return { user: 'local' };
    }

    return Auth.signin(
      (m) => {
        if (m === 'good') {
          setUser(username);
        }
        cb(m);
      },
      username,
      password,
      address.api_url('/api/user_login')
    );
  };

  const stateManager = getStateManager();
  const signout = (cb) => {
    return Auth.signout(() => {
      setUser(null);
      stateManager.wsManager.stop();
      if (stateManager.simulation) stateManager.simulation.stop();
      destroyState();

      cb();
    }, address.api_url('/api/user_logout'));
  };

  const deleteAccount = (cb) => {
    return Auth.deleteAccount((m) => {
      if (m === 'good') {
        setUser(null);
        stateManager.wsManager.stop();
        if (stateManager.simulation) stateManager.simulation.stop();
        destroyState();
      }
      cb(m);
    }, address.api_url('/api/del_user'));
  };

  const register = (username, password, password2, email, cb) => {
    return Auth.register(
      (m) => {
        if (m === 'good') {
          setUser(username);
        }
        cb(m);
      },
      username,
      password,
      password2,
      email,
      address.api_url('/api/user_registration')
    );
  };

  return {
    user,
    signin,
    signout,
    register,
    deleteAccount
  };
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
