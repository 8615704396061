import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import MuiSwitch from '@material-ui/core/Switch';

import { ShowSetting } from '../FormWidgets/ShowSliderSetting';
import { ExpandableSettingsGroupGrid, ValueBox } from '../FormWidgets/SettingGroup';
import { LoadSettingsDialog } from '../Utils/load_settings';
import Desc from '../FormWidgets/Description';

import { settingControls, useSavedSettings } from './settingFunctions';
import { SettingActions } from '../components';
import { useTranslation } from 'react-i18next';
import { irrigationStrategyMapping } from '../Constants/constants';
import { FlexSelect } from '../FormWidgets/Select';
import { MyTimePicker } from '../FormWidgets/DatePicker';

export default function IrrigationSettings({ controller, parent, ...other }) {
  let { tab_id } = useParams();
  let { t } = useTranslation();
  const [values, setValues] = useState(parent.settings[tab_id]);
  const controls = settingControls(controller, parent, tab_id, values, setValues);
  const { openLoadSettingsDialog, closeLoadSettingsDialog } = useSavedSettings(parent, controls);
  
  console.log("[irr] ", values);

  return (
    <React.Fragment>
      <LoadSettingsDialog
        open={openLoadSettingsDialog}
        handleClose={closeLoadSettingsDialog}
        controls={controls}
      />

      <SettingActions onReset={controls.resetSettings} onSave={controls.saveSettings} />

      <ExpandableSettingsGroupGrid label="phase_0_activation" expand_on_load={true}>
        <ShowSetting
          id="irrigation_p0_dry_back_abs"
          controller={controller}
          cur_value={values.irrigation_p0_dry_back_abs}
          controller_value={parent.settings.irrigation.irrigation_p0_dry_back_abs}
          controls={controls}
        />

        <ExpandableSettingsGroupGrid label={t("more")} outside={true}>
          <ShowSetting
            id="irrigation_p0_phase_timeout_minutes"
            controller={controller}
            cur_value={values.irrigation_p0_phase_timeout_minutes}
            controller_value={parent.settings.irrigation.irrigation_p0_phase_timeout_minutes}
            controls={controls}
          />
          <ValueBox controller={controller} id={"irrigation_p0_enable"}>
            <Typography gutterBottom>
              <MuiSwitch
                checked={values.irrigation_p0_enable === 1}
                onClick={(event) => {
                  controls.setValue('irrigation_p0_enable', event.target.checked ? 1 : 0);
                }}
              />
              <Desc id="irrigation_p0_enable" />
            </Typography>
          </ValueBox>
        </ExpandableSettingsGroupGrid>
      </ExpandableSettingsGroupGrid>


      <ExpandableSettingsGroupGrid label={t("phase_1_pamp_up")}>
        <ShowSetting
          id="irrigation_p1_target_vwc_abs"
          controller={controller}
          cur_value={values.irrigation_p1_target_vwc_abs}
          controller_value={parent.settings.irrigation.irrigation_p1_target_vwc_abs}
          controls={controls}
        />

        <ExpandableSettingsGroupGrid label={t("more")} outside={true}>
          <ShowSetting
            id="irrigation_p1_shot_size_vwc_rel"
            controller={controller}
            cur_value={values.irrigation_p1_shot_size_vwc_rel}
            controller_value={parent.settings.irrigation.irrigation_p1_shot_size_vwc_rel}
            controls={controls}
          />
          <ShowSetting
            id="irrigation_p1_dry_back_rel"
            controller={controller}
            cur_value={values.irrigation_p1_dry_back_rel}
            controller_value={parent.settings.irrigation.irrigation_p1_dry_back_rel}
            controls={controls}
          />
          <ShowSetting
            id="irrigation_p1_wait_after_shot_minutes"
            controller={controller}
            cur_value={values.irrigation_p1_wait_after_shot_minutes}
            controller_value={parent.settings.irrigation.irrigation_p1_wait_after_shot_minutes}
            controls={controls}
          />
          <ShowSetting
            id="irrigation_p1_shot_duration_timeout_minutes"
            controller={controller}
            cur_value={values.irrigation_p1_shot_duration_timeout_minutes}
            controller_value={parent.settings.irrigation.irrigation_p1_shot_duration_timeout_minutes}
            controls={controls}
          />
          <ShowSetting
            id="irrigation_p1_phase_timeout_minute"
            controller={controller}
            cur_value={values.irrigation_p1_phase_timeout_minute}
            controller_value={parent.settings.irrigation.irrigation_p1_phase_timeout_minute}
            controls={controls}
          />
          <ValueBox controller={controller} id={"irrigation_p1_memo_max_vwc"}>
            <Typography gutterBottom>
              <MuiSwitch
                checked={values.irrigation_p1_memo_max_vwc === 1}
                onClick={(event) => {
                  controls.setValue('irrigation_p1_memo_max_vwc', event.target.checked ? 1 : 0);
                }}
              />
              <Desc id="irrigation_p1_memo_max_vwc" />
            </Typography>
          </ValueBox>
        </ExpandableSettingsGroupGrid>
      </ExpandableSettingsGroupGrid>

      <ExpandableSettingsGroupGrid label={t("phase_2_maintain")}>
        <ValueBox controller={controller} id={'irrigation_p2_strategy'}>
          <Typography gutterBottom>
            <Desc id="irrigation_p2_strategy" />
          </Typography>
          <FlexSelect
            id="irrigation_p2_strategy"
            controller={controller} 
            value={values.irrigation_p2_strategy} 
            controller_value={parent.settings.irrigation.irrigation_p2_strategy}
            controls={controls}
            mapping={irrigationStrategyMapping}
            onChange={(event) =>
              controls.setValue("irrigation_p2_strategy", event.target.value)
            }
          />
        </ValueBox>

        { values.irrigation_p2_strategy === 0 ? (
          <ShowSetting
            id="irrigation_p2_drain_sp"
            controller={controller}
            cur_value={values.irrigation_p2_drain_sp}
            controller_value={parent.settings.irrigation.irrigation_p2_drain_sp}
            controls={controls}
          />
        ) : (
          <ShowSetting
            id="irrigation_p2_target_vwc_abs"
            controller={controller}
            cur_value={values.irrigation_p2_target_vwc_abs}
            controller_value={parent.settings.irrigation.irrigation_p2_target_vwc_abs}
            controls={controls}
          />
        )}          
        <ShowSetting
          id="irrigation_p2_dry_back_abs"
          controller={controller}
          cur_value={values.irrigation_p2_dry_back_abs}
          controller_value={parent.settings.irrigation.irrigation_p2_dry_back_abs}
          controls={controls}
        />

        <ValueBox controller={controller} id={'irrigation_p2_end_before_sunset'}>
          <Typography gutterBottom>
            <Desc id="irrigation_p2_end_before_sunset"/>
          </Typography>
          <MyTimePicker
            value={values.irrigation_p2_end_before_sunset}
            name="irrigation_p2_end_before_sunset"
            id="irrigation_p2_end_before_sunset"
            onChange={(value) => controls.setValue(
              'irrigation_p2_end_before_sunset', 
              value.toLocaleTimeString('de-DE')
            )}
          />
        </ValueBox>

        <ExpandableSettingsGroupGrid label={t("more")} outside={true}>
          <ShowSetting
            id="irrigation_p2_shot_duration_timeout_minutes"
            controller={controller}
            cur_value={values.irrigation_p2_shot_duration_timeout_minutes}
            controller_value={parent.settings.irrigation.irrigation_p2_shot_duration_timeout_minutes}
            controls={controls}
          />
          <ShowSetting
            id="irrigation_p2_wait_after_shot_s"
            controller={controller}
            cur_value={values.irrigation_p2_wait_after_shot_s}
            controller_value={parent.settings.irrigation.irrigation_p2_wait_after_shot_s}
            controls={controls}
          />
        </ExpandableSettingsGroupGrid>
      </ExpandableSettingsGroupGrid>

      <ExpandableSettingsGroupGrid label={t("phase_3_drydown")}>
        <ShowSetting
          id="irrigation_p3_min_vwc_abs"
          controller={controller}
          cur_value={values.irrigation_p3_min_vwc_abs}
          controller_value={parent.settings.irrigation.irrigation_p3_min_vwc_abs}
          controls={controls}
        />

        <ExpandableSettingsGroupGrid label={t("more")} outside={true}>
          <ShowSetting
            id="irrigation_p3_shot_size_rel"
            controller={controller}
            cur_value={values.irrigation_p3_shot_size_rel}
            controller_value={parent.settings.irrigation.irrigation_p3_shot_size_rel}
            controls={controls}
          />
          <ShowSetting
            id="irrigation_p3_min_wait_after_shot"
            controller={controller}
            cur_value={values.irrigation_p3_min_wait_after_shot}
            controller_value={parent.settings.irrigation.irrigation_p3_min_wait_after_shot}
            controls={controls}
          />
        </ExpandableSettingsGroupGrid>
      </ExpandableSettingsGroupGrid>

      <SettingActions onReset={controls.resetSettings} onSave={controls.saveSettings} />
    </React.Fragment>
  );
}
