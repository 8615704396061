import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import { ShowSetting } from '../FormWidgets/ShowSliderSetting';
import { SettingGroupGrid, ValueBox } from '../FormWidgets/SettingGroup';
import { LoadSettingsDialog } from '../Utils/load_settings';
import { EnableDisableSelect } from '../FormWidgets/Select.js';
import Desc from '../FormWidgets/Description';

import { settingControls, useSavedSettings } from './settingFunctions';
import { SettingActions } from '../components';

export default function LightsSettings({ controller, parent, ...other }) {
  let { tab_id } = useParams();
  const [values, setValues] = useState(parent.settings[tab_id]);
  const controls = settingControls(controller, parent, tab_id, values, setValues);
  const { openLoadSettingsDialog, closeLoadSettingsDialog } = useSavedSettings(parent, controls);

  return (
    <React.Fragment>
      <LoadSettingsDialog
        open={openLoadSettingsDialog}
        handleClose={closeLoadSettingsDialog}
        controls={controls}
      />

      <SettingActions onReset={controls.resetSettings} onSave={controls.saveSettings} />

      <SettingGroupGrid controller={controller} id={'lights'}>
        <ShowSetting
          id="lights_max"
          controller={controller}
          cur_value={values.lights_max}
          controller_value={parent.settings.lights.lights_max}
          min={values.lights_min}
          controls={controls}
        />
        <ShowSetting
          id="lights_min"
          controller={controller}
          cur_value={values.lights_min}
          controller_value={parent.settings.lights.lights_min}
          max={values.lights_max}
          controls={controls}
        />
      </SettingGroupGrid>

      <SettingGroupGrid controller={controller} id={'lights_sunrise_duration'}>
        <ShowSetting
          id="light_sunrise_duration"
          controller={controller}
          cur_value={values.light_sunrise_duration}
          controller_value={parent.settings.lights.light_sunrise_duration}
          controls={controls}
        />
      </SettingGroupGrid>

      <SettingGroupGrid controller={controller} id={'over_temperature_hl'}>
        <ValueBox controller={controller} id={'lights_dim_over_temp_enable'}>
          <Typography gutterBottom>
            <Desc id="lights_dim_over_temp_enable" />
          </Typography>
          <EnableDisableSelect
            value={values.lights_dim_over_temp_enable}
            name="lights_dim_over_temp_enable"
            onChange={(event, value) => {
              controls.setValue('lights_dim_over_temp_enable', event.target.value);
            }}
          />
        </ValueBox>
        <ShowSetting
          id="lights_dim_over_temp_temp_day"
          controller={controller}
          cur_value={values.lights_dim_over_temp_temp_day}
          controller_value={parent.settings.lights.lights_dim_over_temp_temp_day}
          min={parent.settings.climate.temp_sp_day / 10 + 1}
          controls={controls}
          type="float"
        />
        <ShowSetting
          id="lights_dim_over_temp_temp_co2"
          controller={controller}
          cur_value={values.lights_dim_over_temp_temp_co2}
          controller_value={parent.settings.lights.lights_dim_over_temp_temp_co2}
          min={parent.settings.co2.temp_sp_co2 / 10 + 1}
          controls={controls}
          type="float"
        />
        <ShowSetting
          id="lights_dim_over_temp_reduct_ph1"
          controller={controller}
          cur_value={values.lights_dim_over_temp_reduct_ph1}
          controller_value={parent.settings.lights.lights_dim_over_temp_reduct_ph1}
          min={values.lights_min - 1}
          max={values.lights_max}
          controls={controls}
          off_at={values.lights_min - 1}
        />
        <ShowSetting
          id="lights_dim_over_temp_reduct_ph2"
          controller={controller}
          cur_value={values.lights_dim_over_temp_reduct_ph2}
          controller_value={parent.settings.lights.lights_dim_over_temp_reduct_ph2}
          min={values.lights_min - 1}
          max={values.lights_dim_over_temp_reduct_ph1}
          controls={controls}
          off_at={values.lights_min - 1}
        />
        <ShowSetting
          id="lights_dim_over_temp_reduct_ph3"
          controller={controller}
          cur_value={values.lights_dim_over_temp_reduct_ph3}
          controller_value={parent.settings.lights.lights_dim_over_temp_reduct_ph3}
          min={values.lights_min - 1}
          max={values.lights_dim_over_temp_reduct_ph2}
          controls={controls}
          off_at={values.lights_min - 1}
        />
        <ShowSetting
          id="lights_dim_over_temp_reduct_wait_minute"
          controller={controller}
          cur_value={values.lights_dim_over_temp_reduct_wait_minute}
          controller_value={parent.settings.lights.lights_dim_over_temp_reduct_wait_minute}
          controls={controls}
        />
      </SettingGroupGrid>

      <SettingActions onReset={controls.resetSettings} onSave={controls.saveSettings} />
    </React.Fragment>
  );
}
