import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { WarnLevels } from '../Constants/constants';
import _ from 'lodash';

import getStateManager from '../StateManager.js';
import { GetIndexedId } from '../Utils/utils';

export function writeTempSettings(parent) {
  if (!_.isEmpty(parent.temp_settings)) {
    localStorage.setItem(`${parent.id}_temp_settings`, JSON.stringify(parent.temp_settings));
  } else {
    localStorage.removeItem(`${parent.id}_temp_settings`);
  }
}

/*
 * @param parent Can be either a controller or a zone if controller suppors multi zone
 */
const stateManager = getStateManager();
export function settingControls(controller, parent, tab_id, values, setValues) {
  return {
    saveTempSettings: (id, v) => {
      if (parent.temp_settings[tab_id] == null) parent.temp_settings[tab_id] = {};
      if (id.indexOf(':') > -1) {
        const [index, field_id] = GetIndexedId(id);
        parent.temp_settings[tab_id][index][field_id] = v;
      } else parent.temp_settings[tab_id][id] = v;
      // temp = temporary, _not_ temperature
      writeTempSettings(parent);
    },
    // single setting
    resetSetting: function (id) {
      if (id.indexOf(':') > -1) {
        const [index, field_id] = GetIndexedId(id);
        delete parent.temp_settings[tab_id][index][field_id];
        this.setValue(id, parent.settings[tab_id][index][field_id], false);
        delete parent.temp_settings[tab_id][index][field_id];
      } else {
        delete parent.temp_settings[tab_id][id];
        setValues({ ...values, [id]: parent.settings[tab_id][id] });
        delete parent.temp_settings[tab_id][id];
      }
      writeTempSettings(parent);
    },
    // all settings
    resetSettings: function () {
      setValues(_.cloneDeep(parent.settings[tab_id]));
      delete parent.temp_settings[tab_id];
      writeTempSettings(parent);
    },

    restoreTempSettings: () => {
      setValues(
        _.merge(_.cloneDeep(parent.settings[tab_id]), _.cloneDeep(parent.temp_settings[tab_id]))
      );
    },

    saveSettings: (transform = null, do_transfrom = false) => {
      // Check if the transform parameter is a function, otherwise set it to null
      if (typeof transform !== 'function') transform = null;
      if (typeof do_transfrom !== 'boolean') do_transfrom = false;
      if (do_transfrom) {
        stateManager.saveSettings(controller, parent, transform(values), tab_id, null);
      } else {
        stateManager.saveSettings(controller, parent, values, tab_id, transform);
      }
      delete parent.temp_settings[tab_id];
      writeTempSettings(parent);
    },

    setValue: function (id, v, saveTemp = true) {
      if (id.indexOf(':') > -1) {
        const [index, field_id] = GetIndexedId(id);
        let updatedValues = values;
        updatedValues[index][field_id] = v;
        setValues(updatedValues);
      } else setValues({ ...values, [id]: v });
      // turn this off in slider's onChange to improve performance
      if (saveTemp) this.saveTempSettings(id, v);
    },

    setValueMinMaxSlider: function (id_min, id_max, v_min, v_max, saveTemp = true) {
      setValues({ ...values, [id_min]: v_min, [id_max]: v_max });

      // turn this of in slider's onChange to improve performance
      if (saveTemp) {
        this.saveTempSettings(id_min, v_min);
        this.saveTempSettings(id_max, v_max);
      }
    },

    resetMinMaxSlider: function (id_min, id_max) {
      delete parent.temp_settings[tab_id][id_min];
      delete parent.temp_settings[tab_id][id_max];
      setValues({
        ...values,
        [id_min]: parent.settings[tab_id][id_min],
        [id_max]: parent.settings[tab_id][id_max]
      });
      writeTempSettings(parent);
    }
  };
}

export const useSavedSettings = (parent, controls, sid = null, custom_differ = null) => {
  let { t } = useTranslation();
  let { enqueueSnackbar } = useSnackbar();
  let { tab_id } = useParams();
  tab_id = sid ?? tab_id;
  const [open_load_settings_dialog, setOpenLoadSettingsDialog] = useState(false);
  const [settings_loaded, setSettingsLoaded] = useState(false);

  const differ = custom_differ
    ? custom_differ
    : () => {
        return (
          !_.isEmpty(parent.temp_settings[tab_id]) &&
          !_.isEqual(parent.settings[tab_id], parent.temp_settings[tab_id])
        );
      };

  useEffect(() => {
    // Get whether saved settings differ from controller settings.
    const differing = differ();

    const auto_load = localStorage.getItem('auto_load');
    setOpenLoadSettingsDialog(differing && !auto_load);
    // Check whether settings should be loaded (not yet loaded and differing)
    if (!settings_loaded && differing) {
      // Auto apply saved settings.
      if (auto_load === '1') {
        controls.resetSettings();
        enqueueSnackbar(t('auto_saved_loaded'), { variant: 'info' });
        setSettingsLoaded(true);
      }
      // Auto delete saved settings.
      else if (auto_load === '0') {
        parent.temp_settings[tab_id] = {};
        enqueueSnackbar(t('auto_saved_deleted'), { variant: 'info' });
      }
    }
    // Warning when leaving page although unsaved settings exist.
    return () => {
      console.log('useSavedSettings leave', tab_id, !_.isEmpty(parent.temp_settings[tab_id]));
      if (differ()) {
        enqueueSnackbar(t('auto_save_leaving_page_warn') + ' ' + t(tab_id), {
          variant: WarnLevels['unsaved_settings']
        });
      }
    };
  }, []);

  return {
    openLoadSettingsDialog: open_load_settings_dialog,
    closeLoadSettingsDialog: () => {
      setOpenLoadSettingsDialog(false);
    }
  };
};
