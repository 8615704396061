import { convert_settings, multi_io } from '../Constants/settings';
import { RemoveMessageOfController, UpdateHistory, TransformHistoryValue } from '../Utils/utils.js';
import { history_values } from '../Constants/constants';
import { initialHistory } from '../Constants/InitialSettings.js';

import _ from 'lodash';

export default function controllerOnMessage(wsManager, resp) {
  const controller_id = resp !== undefined ? resp.internal_id : undefined;
  const cur_controller = wsManager.store.getControllerById(controller_id);
  const controller_name = cur_controller !== undefined ? cur_controller.name : controller_id;

  const messages = wsManager.store.messages;
  const data = resp['data'] ?? {};

  const hooks = wsManager.store.hooks;

  // Parse message and get command.
  if (!('command' in resp)) console.log('[cont-ws] invalid command!');
  let command = resp['command'];
  if (command !== 'update_values') {
    console.log('[cont-ws] got command:', command, resp);
  }

  if (command === 'controller_deleted') {
    if (cur_controller.simulation) {
      cur_controller.simulation.stop();
      if (cur_controller.id === wsManager.store.simulation.controller.id) {
        wsManager.store.simulation = null;
      }
    }

    wsManager.store.controllers = wsManager.store.controllers.filter(function (cont) {
      return cont.id !== controller_id;
    });

    sessionStorage.setItem('controllers', JSON.stringify(wsManager.store.controllers));

    hooks.enqueueSnackbar(controller_name + ' - deleted', { variant: 'info' });
  } else if (command === 'controller_deleted_failed') {
    //wsManager.store.curControllerId = controller_id;
    hooks.setOpenDeleteDialog(true);
  }

  // cmd: message:
  if (command === 'message') {
    // Create message, by translating simple string or several strings in array.
    let text = '';
    for (const part of data['message_parts']) text += hooks.t(part);
    // Get other data.
    const type = data['type'] === 'information' ? 'info' : data['type'];
    // Add snackbar
    hooks.enqueueSnackbar(controller_name + ' - ' + text, { variant: type });
    // If not blocked, add to log.
    if (!data['dont_log']) {
      const timestamp = data['timestamp'];
      const newMessage = {
        timestamp: timestamp,
        id: timestamp + text,
        type: type,
        message: text,
        controller: controller_name
      };

      messages.messages.push(newMessage);
      messages.unreadMessages = true;
    }
  }

  //cmd: send_all_messages
  else if (command === 'send_all_messages') {
    // Remove all messages from this controller
    let currentMessages = RemoveMessageOfController(
      wsManager.store.messages.messages,
      controller_name
    );
    // Add new messages for this controller.
    const newMessages = data['all_messages'];
    for (var i = 0; i < newMessages.length; i++) {
      const message = newMessages[i];
      let text = '';
      for (const part of message['message_parts']) text += hooks.t(part);
      const type = message['type'] === 'information' ? 'info' : message['type'];
      const timestamp = message['timestamp'];
      currentMessages.push({
        timestamp: timestamp,
        id: timestamp + text,
        type: type,
        message: text,
        controller: controller_name
      });
    }

    messages.messages = currentMessages;
    messages.unreadMessages = currentMessages.length > 0;
  }

  //cmd: resp_action
  else if (command === 'resp_action') {
    const new_info = resp['data']['info'];
    let message =
      'For ' +
      new_info['id'] +
      ('value' in new_info ? ' with value ' + new_info['value'] : '') +
      ': ';
    let type = '';
    if (resp.data['success'] === true) {
      message += 'success';
      type = 'success';
    } else {
      message += 'failed';
      type = 'error';
    }

    hooks.enqueueSnackbar(message, { variant: type });
  }

  // cmd: update_values:
  else if (command === 'update_values') {
    // Update controller last-received:
    cur_controller.connection.last_received = Date.now();

    if ('zones' in data) {
      let found = [];
      for (let zone of data.zones) {
        if (cur_controller.zones[zone.id] == null) {
          cur_controller.zones[zone.id] = {
            settings: {},
            temp_settings: {},
            infos: {},
            history: { data: _.cloneDeep(initialHistory) },
            id: zone.id
          };
        }

        if (cur_controller.zones[zone.id].name !== zone.name) {
          cur_controller.zones[zone.id].name = zone.name;
        }

        if (Object.keys(zone.settings).length > 4) {
          cur_controller.connection.request_values_full = true;
          console.log('[cont-ws] recieved more than info: ', Object.keys(data));
        }

        update_values(cur_controller.zones[zone.id], zone.settings);
        found.push(zone.id);
      }

      // delete obsolete zones
      for (let zone of Object.values(cur_controller.zones)) {
        if (found.indexOf(zone.id) === -1) {
          delete cur_controller.zones[zone.id];
        }
      }

      if ('slaves' in data) {
        cur_controller.slaves = data.slaves;
      }

      if ('system' in data) {
        cur_controller.settings.system = _.merge(
          cur_controller.settings?.system ?? {},
          data.system
        );
      }
    } else {
      update_values(cur_controller, data);

      if (Object.keys(data).length > 4) {
        cur_controller.connection.request_values_full = true;
        console.log('[cont-ws] recieved more than info: ', Object.keys(data));
      }
    }
  }
  // cmd: send_info_graphs
  else if (command === 'send_info_graphs') {
    if ('zones' in data) {
      for (const [key, graph] of Object.entries(data.zones)) {
        set_info_graphs(cur_controller.zones[key].infos, graph);
      }
    } else {
      set_info_graphs(cur_controller.infos, data);
    }
  }
  // cmd: send_info_graphs
  else if (command === 'fs_status') {
    if ('zones' in data) {
      // TODO: NOT IMPLEMENTED FOR ZONES YET!!
      for (const [key, value] of Object.entries(data.zones)) {
        cur_controller.zones[key].fs_status = value;
      }
    } else {
      cur_controller.fs_status = data;
    }
  }

  // cmd: send_recent_history:
  else if (
    cur_controller.history.data.progress < 100 &&
    ['send_recent_history', 'send_daily_history', 'send_history'].indexOf(command) !== -1
  ) {
    if ('zones' in data) {
      // TODO (fux): Why is history never used?
      for (const [key, history] of Object.entries(data.zones)) {
        if (!('hidden' in cur_controller.zones[key].history.data)) {
          cur_controller.zones[key].history.data.hidden = {};
        }

        UpdateHistory(cur_controller.zones[key].history.data, data.zones[key]);
      }
    } else {
      // handle history split into multiple message caused by limitations of ESP
      UpdateHistory(cur_controller.history.data, data);
    }
  }
}

function update_values(parent, settings) {
  // Convert timing and replace 0xFFFF values with -1
  const data = convert_settings(settings);
  //const data = resp['data'];
  const timestamp = data['timestamp'];

  // Update all values
  parent.settings = _.merge(parent.settings ?? {}, data);
  parent.infos = _.merge(parent.infos ?? {}, parent.settings.cur_values);
  parent.multi_io_infos = _.assign(parent.multi_io_infos ?? [], parent.settings.multi_io_infos);
  delete parent.settings.cur_values;
  delete parent.settings.multi_io_infos;

  // add to history if
  //  a) values is in history,
  //  b) history is complete and
  //  c) matches resolution
  if (parent.history.data.progress === 100) {
    const elapsed_time = timestamp - parent.history.data.last_ts;
    if (elapsed_time > parent.history.data.resolution * 1000) {
      for (const type of history_values) {
        // Get transformed value (0xFFFF=-1, co2=val, other: val/10)
        parent.history.data[type].push(TransformHistoryValue(parent.infos[type], type));
      }
      parent.history.data['time'].push(timestamp);
      parent.history.data.last_ts = timestamp;
    }
  }

  // update controller-date and time from current values to assure live
  // updating of said values (instead of values remaning
  if ('time' in data['cur_values'] && parent.settings.timing) {
    const time = data['cur_values']['time'].split(':');
    parent.settings.timing.controller_time = data['cur_values']['time'];
    parent.settings.timing.time_cont_hour = parseInt(time[0]);
    parent.settings.timing.time_cont_min = parseInt(time[1]);
    parent.settings.timing.time_cont_sec = parseInt(time[2]);
  }
  if ('date' in data['cur_values'] && parent.settings.timing) {
    const date = data['cur_values']['date'].split('.');
    parent.settings.timing.controller_date = date[2] + '-' + date[1] + '-' + date[0];
    parent.settings.timing.time_cont_year = parseInt(date[2]);
    parent.settings.timing.time_cont_month = parseInt(date[1]);
    parent.settings.timing.time_cont_day = parseInt(date[0]);
  }

  // TODO: check if wee need this
  // handle memo names:
  if ('multi_out' in data && 'memo_names' in data['multi_out']) {
    for (const memo_name of data['multi_out']['memo_names'])
      parent.settings['multi_out'][memo_name['id']] = memo_name['name'];
  }

  if ('multi_io' in data && 'memo_names' in data['multi_io']) {
    for (const memo_name of data['multi_io']['memo_names'])
      parent.settings['multi_io'][memo_name['id']] = memo_name['name'];
  }
}

function set_info_graphs(infos, data) {
  const hgraph = data['humidity_graph'];
  infos['humidity_graph'] = hgraph.map((val) => TransformHistoryValue(val, 'humidity'));
  const tgraph = data['temp_graph'];
  infos['temp_graph'] = tgraph.map((val) => TransformHistoryValue(val, 'temp'));
}
