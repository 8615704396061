import {
  SimuInfoGraph,
  SimulationHistoryHighRes,
  SimulationGetSendValueRequest
} from '../Utils/simulation_utils.js';

import { Ran } from '../Utils/utils.js';
import { resolutions } from '../Constants/constants';

export default function simulationOnMessage(simulation, resp) {
  if (!simulation) return;
  const username = simulation.username;
  console.log('[simulation-ws] Simulation got command: ', resp);
  //Try to parse command.
  let command = '';
  try {
    command = resp['command'];
  } catch (err) {
    return;
  }
  console.log('[simulation-ws] Simulation got command: ', command);

  //Setup initial response
  var json_response = { username: username, command: '' };

  // request_values -> send values to server
  if (command === 'request_values') {
    json_response = SimulationGetSendValueRequest(false, simulation.controller.id);
  } else if (command === 'request_values_full') {
    json_response = SimulationGetSendValueRequest(true, simulation.controller.id);
  }
  // action -> randomly reply with true or false
  else if (command === 'action') {
    const data = resp['data'];
    // Only answer if action is not the delete-controller-action.
    if (data['id'] !== 'reset_controller_credentials') {
      json_response['command'] = 'resp_action';
      json_response['data'] = { success: true };
      json_response['data']['info'] = { id: data['id'] };
      if ('value' in data) json_response['data']['info']['value'] = data['value'];
    }
  }
  // update_setpoints -> send error or success message.
  else if (command === 'update_setpoints') {
    // Construct response message.
    const category = Object.keys(resp['data'])[0];
    json_response['command'] = 'message';
    json_response['data'] = {};
    if (Ran(0, 1) === 1) {
      json_response['data']['type'] = 'success';
      json_response['data']['message_parts'] = [
        category,
        ': ',
        'all',
        ' ',
        'gc_ms_settings_accepted'
      ];
    } else {
      json_response['data']['type'] = 'error';
      json_response['data']['message_parts'] = [
        category,
        ': ',
        'gc_ms_nothing_changed',
        ' - 1 ',
        'gc_ms_setting_rejected',
        ': 5'
      ];
    }
    json_response['data']['timestamp'] = Date.now();
  } else if (command === 'clear_msg_log') {
    json_response['command'] = 'send_all_messages';
    json_response['internal_id'] = simulation.id;
    json_response['data'] = { all_messages: [] };
  } else if (command === 'get_all_messages') {
    // json_response["command"] = "send_all_messages";
    // var date = new Date();
    // json_response["data"] = {
    //   "all_messages":[
    //     {
    //       "message_parts":["test_message", " ", "test_message_2"],
    //       "type":"info",
    //       "timestamp":date.getTime(),
    //     }
    //   ]
    // };
  } else if (command === 'get_recent_history') {
    const date = new Date();
    json_response['command'] = 'send_recent_history';
    json_response['internal_id'] = simulation.controller.id;
    // Send 1. block
    json_response['data'] = SimulationHistoryHighRes(60, resolutions.recent, 0, 2);
    json_response['data']['progress'] = 0;
    json_response['data']['part'] = 0;
    json_response['data']['timestamp'] = date.getTime();
    simulation.socket.send(JSON.stringify(json_response));
    // Send 2. block
    json_response['data'] = SimulationHistoryHighRes(60, resolutions.recent, 1, 2);
    json_response['data']['progress'] = 100;
    json_response['data']['part'] = 1;
    json_response['data']['timestamp'] = date.getTime();
  } else if (command === 'get_daily_history') {
    const date = new Date();
    json_response['command'] = 'send_daily_history';
    json_response['internal_id'] = simulation.controller.id;
    // Send 1. block
    json_response['data'] = SimulationHistoryHighRes(720, resolutions.daily, 0, 2);
    json_response['data']['progress'] = 0;
    json_response['data']['part'] = 0;
    json_response['data']['timestamp'] = date.getTime();
    simulation.socket.send(JSON.stringify(json_response));
    // Send 2. block
    json_response['data'] = SimulationHistoryHighRes(720, resolutions.daily, 1, 2);
    json_response['data']['progress'] = 100;
    json_response['data']['part'] = 1;
    json_response['data']['timestamp'] = date.getTime();
  } else if (command === 'get_history') {
    const date = new Date();
    json_response['command'] = 'send_history';
    json_response['internal_id'] = simulation.controller.id;
    // Send 1. block
    json_response['data'] = SimulationHistoryHighRes(1175, resolutions.full, 0, 6);
    json_response['data']['progress'] = 0;
    json_response['data']['part'] = 0;
    json_response['data']['timestamp'] = date.getTime();
    simulation.socket.send(JSON.stringify(json_response));
    // Send 2. block
    json_response['data'] = SimulationHistoryHighRes(1175, resolutions.full, 1, 6);
    json_response['data']['progress'] = 20;
    json_response['data']['timestamp'] = date.getTime();
    json_response['data']['part'] = 1;
    simulation.socket.send(JSON.stringify(json_response));
    // Send 3. block
    json_response['data'] = SimulationHistoryHighRes(1175, resolutions.full, 2, 6);
    json_response['data']['progress'] = 40;
    json_response['data']['part'] = 2;
    json_response['data']['timestamp'] = date.getTime();
    // Send 4. block
    json_response['data'] = SimulationHistoryHighRes(1175, resolutions.full, 3, 6);
    json_response['data']['progress'] = 60;
    json_response['data']['part'] = 3;
    json_response['data']['timestamp'] = date.getTime();
    simulation.socket.send(JSON.stringify(json_response));
    // Send 5. block
    json_response['data'] = SimulationHistoryHighRes(1175, resolutions.full, 4, 6);
    json_response['data']['progress'] = 80;
    json_response['data']['timestamp'] = date.getTime();
    json_response['data']['part'] = 4;
    simulation.socket.send(JSON.stringify(json_response));
    // Send 6. block
    json_response['data'] = SimulationHistoryHighRes(1175, resolutions.full, 5, 6);
    json_response['data']['progress'] = 100;
    json_response['data']['part'] = 5;
    json_response['data']['timestamp'] = date.getTime();
  } else if (command === 'resolve_alarm') {
    json_response['command'] = 'alarms_resolved';
    const timestamp = new Date().getTime();
    json_response['data'] = {};
    json_response['data']['alarm_keys'] = [resp['data']['alarm_id']];
    json_response['data']['timestamp'] = timestamp;
  } else if (command === 'get_info_graphs') {
    json_response['command'] = 'send_info_graphs';
    json_response['internal_id'] = simulation.controller.id;
    json_response['data'] = { humidity_graph: [], temp_graph: [] };
    json_response['data']['humidity_graph'] = SimuInfoGraph(600, 760);
    json_response['data']['temp_graph'] = SimuInfoGraph(220, 240);
  } else if (command === 'get_fs_status') {
    console.log('[sumilation-ws] handling cmd `get_fs_status`');
    json_response['command'] = 'fs_status';
    json_response['data'] = {};
    json_response['data']['total_size'] = 10000;
    json_response['data']['free'] = 40;
    json_response['data']['flash_status'] = 1;
    json_response['data']['fs_status'] = 1;
  }

  // Send response, when command was set:
  if (json_response['command'] !== '') {
    if (simulation.socket !== undefined && simulation.socket.readyState === 1) {
      json_response['internal_id'] = simulation.controller.id;
      json_response.socket = simulation.socket;

      console.log('[simulation-ws] sending: ', json_response['command']);
      simulation.sendWSMsg(json_response);
    }
  }
}
