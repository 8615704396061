import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import {
  multiOutMapping,
  multiIOHwTypeMappingSelect,
  multiIOHwTypeMappping
} from '../Constants/constants';

import { EditableLabel } from './EditableLabel';

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.main
  }
}));

export function MultiIOLabel({ func, ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();

  if (!multiIOHwTypeMappping[func] || !multiIOHwTypeMappingSelect[multiIOHwTypeMappping[func]]) {
    return ''; // Return '' if hw_type is invalid or not found in the mapping
  }

  return <label className={classes.label}>{t(multiIOHwTypeMappping[func])}</label>;
}

export default function MultiOutLabel({ func, value, ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  let value_field = '';
  if (func === 0 || func === 1) {
    value_field = value + ' %';
  } else if (value === 0) {
    value_field = ' ' + t('off');
  } else if (value > 0) {
    value_field = ' ' + t('on');
  }

  if (func === 19) {
    return <label className={classes.label}>{t(multiOutMapping[func])}</label>;
  } else {
    return (
      <label className={classes.label}>
        {t(multiOutMapping[func])}: {value_field}
      </label>
    );
  }
}

function MultiOutContent({ controller, parent, index, memo, multi_out }) {
  let { t } = useTranslation();
  return (
    <React.Fragment>
      <b>
        {controller.type === 6000 || (controller.type === 5155 && parent.settings.multi_io != null)
          ? t('multifunctional_io') + ' ' + index
          : t('multifunctional_out') + ' ' + index}
      </b>
      {multi_out != null && (
        <>
          <br />
          Type: <span style={{ fontFamily: 'monospace' }}>{multi_out.type}</span>
        </>
      )}
      <br />
      {memo !== -1 && memo !== '' ? '(' + memo + ')' : <br />}
    </React.Fragment>
  );
}

export function MultiOutHeading({ controller, parent, index, memo, content, multi_out }) {
  return (
    <Typography gutterBottom>
      <MultiOutContent
        controller={controller}
        parent={parent}
        index={index}
        memo={memo}
        multi_out={multi_out}
      />
    </Typography>
  );
}

export function MultiOutHeadingEditable({ controller, parent, index, memo, handleInput }) {
  let { t } = useTranslation();
  return (
    <EditableLabel
      label={
        controller.type === 6000 || (controller.type === 5155 && parent.settings.multi_io != null)
          ? t('multifunctional_io') + ' ' + index
          : t('multifunctional_out') + ' ' + index
      }
      id={'out_' + index + '_memo'}
      value={memo}
      handleInput={handleInput}
      prefix="("
      postfix=")"
    />
  );
}
