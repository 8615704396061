import React, { Fragment, useEffect, useRef, useState, useContext } from 'react';
import Slider from '@material-ui/core/Slider';
import { isMobile } from 'react-device-detect';
import { IconButton } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';

import { settings_borders_and_units } from '../Constants/constants';
import { LockSliderContext } from '../Contexts';
import { useIsMobile } from '../Hooks/useMedia';

function GetMarks(min, mid, max, unit) {
  if (mid === undefined) mid = Math.round((min + max) / 2);
  const marks = [
    {
      value: min,
      label: `${min.toFixed(0)}${unit}`
    },
    {
      value: mid,
      label: `${mid.toFixed(0)}${unit}`
    },
    {
      value: max,
      label: `${max.toFixed(0)}${unit}`
    }
  ];
  return marks;
}

function valuetext(value, unit) {
  return `${value}${unit}`;
}

export function MySlider({ IncClick, DecClick, InputHandler, type, id, num_digits=1, ...other }) {
  const [mobile, setMobile] = useState(isMobile);
  const [lock, setLock] = useState((localStorage.getItem('slider_' + id) || 'true') === 'true');
  const { lockSliderSetting, setLockSliderSetting } = useContext(LockSliderContext);
  const MobileDetected = useIsMobile();

  React.useEffect(() => {
    localStorage.setItem('slider_' + id, lock);
  }, [lock]);

  let locked = function (lockSliderSetting) {
    return (
      !(other.disabled || false) &&
      lock &&
      (lockSliderSetting === 'on' || (lockSliderSetting === 'auto' && mobile))
    );
  };

  return (
    <LockSliderContext.Consumer>
      {(value) => (
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'no-wrap' }}>
            {/* minus */}
            <Box mb={6} sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
              <IconButton
                edge="start"
                onClick={DecClick}
                color="inherit"
                aria-label="decrease"
                mb={2}
                disabled={other.disabled || locked(value)}
              >
                <RemoveIcon />
              </IconButton>
            </Box>

            {/* slider */}
            <Box
              sx={{ display: 'flex', flexGrow: 15, justifyContent: 'center', alignItems: 'center' }}
            >
              {type === 'float' ? (
                <FloatSlider {...other} num_digits={num_digits} locked={locked(value)} />
              ) : type === 'negative' ? (
                <NegativeSlider {...other} locked={locked(value)} />
              ) : type === 'fan_min_max' ? (
                <MinMaxSlider {...other} locked={locked(value)} />
              ) : (
                <BaseSlider {...other} locked={locked(value)} />
              )}
            </Box>

            {/* plus */}
            <Box
              mb={6}
              sx={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                padding: `10px 0px 10px ${MobileDetected ? '10px' : '0'}`
              }}
            >
              <IconButton
                edge="start"
                onClick={IncClick}
                color="inherit"
                aria-label="increase"
                disabled={other.disabled || locked(value)}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Box>

          {((value === 'auto' && mobile) || value === 'on') && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                marginTop: '-30px',
                marginRight: '10px'
              }}
            >
              {lock ? (
                <LockIcon onClick={() => setLock(false)} />
              ) : (
                <LockOpenIcon onClick={() => setLock(true)} />
              )}
            </Box>
          )}
        </Box>
      )}
    </LockSliderContext.Consumer>
  );
}

const StyledSlider = withStyles({
  root: {
    height: 5,
    top: '-15px'
  },
  thumb: {
    height: 18,
    width: 18,
    marginLeft: -9,
    border: '4px solid currentColor',
    marginTop: -6
  },
  active: { color: 'white' },
  track: {
    height: 5,
    borderRadius: 0
  },
  rail: {
    height: 5,
    borderRadius: 0,
    opacity: 0.5
  },
  mark: {
    display: 'none'
  },
  markLabel: {
    marginTop: '60px'
  }
})(Slider);

const StyledSliderLocked = withStyles((theme) => ({
  disabled: (props) => ({
    '& .MuiSlider-thumb ': {
      height: 18,
      width: 18,
      marginLeft: -9,
      color: theme.palette[props.color].main,
      border: '4px solid currentColor',
      marginTop: -6
    },
    '& .MuiSlider-track': {
      backgroundColor: theme.palette[props.color].main,
      height: 5,
      borderRadius: 0
    },
    '& .MuiSlider-rail': {
      backgroundColor: theme.palette[props.color].main,
      opacity: 0.5
    }
  }),

  root: {
    height: 5,
    top: '-15px'
  },
  thumb: {
    height: 18,
    width: 18,
    marginLeft: -9,
    border: '4px solid currentColor',
    marginTop: -6
  },
  active: { color: 'white' },
  track: {
    height: 5,
    borderRadius: 0
  },
  rail: {
    height: 5,
    borderRadius: 0,
    opacity: 0.5
  },
  mark: {
    display: 'none'
  },
  markLabel: {
    marginTop: '60px'
  }
}))(Slider);

const useStyles = makeStyles((theme) => ({
  mark: {
    color: theme.palette.fontcolor
  },
  sliderRoot: {
    touchAction: 'auto'
  }
}));

export function MinMaxSlider({ value, min, max, disabled, locked, ...others }) {
  const marks = GetMarks(min, undefined, max, '%');
  const classes = useStyles();

  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener(
        'touchstart',
        (e) => {
          const isThumb = e.target?.dataset.index;

          if (!isThumb) {
            e.stopPropagation();
          }
        },
        { capture: true }
      );
    }
  });

  return (
    <Fragment>
      {locked ? (
        <StyledSliderLocked
          getAriaValueText={valuetext}
          value={value}
          min={min}
          max={max}
          marks={marks}
          ref={ref}
          classes={{ markLabel: classes.mark, root: classes.sliderRoot }}
          disabled={true}
          {...others}
        />
      ) : (
        <StyledSlider
          getAriaValueText={valuetext}
          value={value}
          min={min}
          max={max}
          marks={marks}
          ref={ref}
          classes={{ markLabel: classes.mark, root: classes.sliderRoot }}
          disabled={disabled || false}
          {...others}
        />
      )}
    </Fragment>
  );
}

function BaseSlider({ name, min, mid, max, unit, disabled, locked, ...other }) {
  if (min === undefined && name in settings_borders_and_units)
    min = settings_borders_and_units[name][0];
  if (max === undefined && name in settings_borders_and_units)
    max = settings_borders_and_units[name][1];
  if (unit === undefined && name in settings_borders_and_units)
    unit = settings_borders_and_units[name][2];
  const marks = GetMarks(min, mid, max, unit);

  const classes = useStyles();
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener(
        'touchstart',
        (e) => {
          const isThumb = e.target?.dataset.index;

          if (!isThumb) {
            e.stopPropagation();
          }
        },
        { capture: true }
      );
    }
  });

  return (
    <Fragment>
      {locked ? (
        <StyledSliderLocked
          getAriaValueText={valuetext}
          marks={marks}
          min={min}
          max={max}
          ref={ref}
          classes={{ markLabel: classes.mark, root: classes.sliderRoot }}
          disabled={true}
          {...other}
        />
      ) : (
        <StyledSlider
          getAriaValueText={valuetext}
          marks={marks}
          min={min}
          max={max}
          ref={ref}
          classes={{ markLabel: classes.mark, root: classes.sliderRoot }}
          disabled={disabled || false}
          {...other}
        />
      )}
    </Fragment>
  );
}

function FloatSlider({ name, value, min, max, unit, disabled, locked, num_digits, ...other }) {
  const adjusted_value = (Number.parseFloat(value) / 10**num_digits).toFixed(num_digits);
  if (min === undefined && name in settings_borders_and_units)
    min = settings_borders_and_units[name][0];
  if (max === undefined && name in settings_borders_and_units)
    max = settings_borders_and_units[name][1];
  if (unit === undefined && name in settings_borders_and_units)
    unit = settings_borders_and_units[name][2];

  const classes = useStyles();
  const marks = GetMarks(min, undefined, max, unit);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener(
        'touchstart',
        (e) => {
          const isThumb = e.target?.dataset.index;

          if (!isThumb) {
            e.stopPropagation();
          }
        },
        { capture: true }
      );
    }
  });
  return (
    <Fragment>
      {locked ? (
        <StyledSliderLocked
          getAriaValueText={valuetext}
          marks={marks}
          step={0.1}
          min={min}
          max={max}
          value={adjusted_value}
          ref={ref}
          classes={{ markLabel: classes.mark, root: classes.sliderRoot }}
          disabled={true}
          {...other}
        />
      ) : (
        <StyledSlider
          getAriaValueText={valuetext}
          marks={marks}
          step={0.1}
          min={min}
          max={max}
          value={adjusted_value}
          ref={ref}
          classes={{ markLabel: classes.mark, root: classes.sliderRoot }}
          disabled={disabled || false}
          {...other}
        />
      )}
    </Fragment>
  );
}

function NegativeSlider({ name, value, min, max, unit, disabled, locked, ...other }) {
  const adjusted_value = value - 127;
  if (min === undefined && name in settings_borders_and_units)
    min = settings_borders_and_units[name][0];
  if (max === undefined && name in settings_borders_and_units)
    max = settings_borders_and_units[name][1];
  if (unit === undefined && name in settings_borders_and_units)
    unit = settings_borders_and_units[name][2];

  const classes = useStyles();
  const marks = GetMarks(min, undefined, max, unit);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener(
        'touchstart',
        (e) => {
          const isThumb = e.target?.dataset.index;

          if (!isThumb) {
            e.stopPropagation();
          }
        },
        { capture: true }
      );
    }
  });
  return (
    <Fragment>
      {locked ? (
        <StyledSliderLocked
          getAriaValueText={valuetext}
          marks={marks}
          min={min}
          max={max}
          value={adjusted_value}
          ref={ref}
          classes={{ markLabel: classes.mark, root: classes.sliderRoot }}
          disabled={true}
          {...other}
        />
      ) : (
        <StyledSlider
          getAriaValueText={valuetext}
          marks={marks}
          min={min}
          max={max}
          value={adjusted_value}
          ref={ref}
          classes={{ markLabel: classes.mark, root: classes.sliderRoot }}
          disabled={disabled || false}
          {...other}
        />
      )}
    </Fragment>
  );
}

export function GraphSlider({ value, max, disabled, locked, ...others }) {
  const classes = useStyles();

  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener(
        'touchstart',
        (e) => {
          const isThumb = e.target?.dataset.index;

          if (!isThumb) {
            e.stopPropagation();
          }
        },
        { capture: true }
      );
    }
  });
  return (
    <Fragment>
      {locked ? (
        <StyledSliderLocked
          getAriaValueText={valuetext}
          value={value}
          min={0}
          max={max}
          ref={ref}
          classes={{ markLabel: classes.mark, root: classes.sliderRoot }}
          disabled={true}
          {...others}
        />
      ) : (
        <StyledSlider
          getAriaValueText={valuetext}
          value={value}
          min={0}
          max={max}
          ref={ref}
          classes={{ markLabel: classes.mark, root: classes.sliderRoot }}
          disabled={disabled || false}
          {...others}
        />
      )}
    </Fragment>
  );
}
