import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

import { GetSaveButtonStyle as saveButtonStyle } from '../../Styles/styles';

export const ActionButtons = ({ onSave, onReset, on_default }) => {
  const { t } = useTranslation();
  return (
    <Box style={saveButtonStyle()}>
      <Button onClick={onSave} disabled={on_default} color="primary" variant="contained">
        {t('save')}
      </Button>
      <Button
        onClick={(e, v) => {
          onReset(e, v);
        }}
        color="secondary"
      >
        {t('reset')}
      </Button>
    </Box>
  );
};
