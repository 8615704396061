import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  value: {
    color: theme.palette.primary.main,
    fontSize: '3em',
    fontWeight: 500
  }
}));

export function NormalValue({ name, unit, value, style, ...other }) {
  const classes = useStyles();

  if (value === -1) {
    return (
      <label name={name} className={classes.value} style={style}>
        --- {unit}
      </label>
    );
  } else {
    return (
      <label name={name} className={classes.value} style={style}>
        {value} {unit}
      </label>
    );
  }
}

export function FloatValue({ name, unit, value, style, num_digits = 1, ...other }) {
  const adjusted_value = (Number.parseFloat(value) / 10 ** num_digits).toFixed(num_digits);
  const classes = useStyles();
  if (value === -1) {
    return (
      <label name={name} className={classes.value} style={style}>
        --- {unit}
      </label>
    );
  } else {
    return (
      <label name={name} className={classes.value} style={style}>
        {adjusted_value} {unit}
      </label>
    );
  }
}

export function NegativeValue({ name, unit, value, style, ...other }) {
  const adjusted_value = value - 4000;
  const classes = useStyles();
  if (value === -1) {
    return (
      <label name={name} className={classes.value} style={style}>
        --- {unit}
      </label>
    );
  } else {
    return (
      <label name={name} className={classes.value} style={style}>
        {adjusted_value} {unit}
      </label>
    );
  }
}
