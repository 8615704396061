import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';

import { DatePicker, MyTimePicker } from '../FormWidgets/DatePicker.js';
import Desc from '../FormWidgets/Description';
import { SettingGroup, ValueBox } from '../FormWidgets/SettingGroup';
import { EventGapSelect } from '../FormWidgets/Select.js';
import { LoadSettingsDialog } from '../Utils/load_settings';
import { SettingActions } from '../components/index.js';

import _ from 'lodash';
import { getEventTimerIds, getIntervalTimerIds, addZ } from '../Utils/utils.js';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { settingControls, useSavedSettings } from './settingFunctions';

export default function TimingSettings({ controller, parent, setPhase, ...other }) {
  let { t } = useTranslation();
  let { tab_id } = useParams();

  const [values, setValues] = useState(parent.settings[tab_id]);
  const controls = settingControls(controller, parent, tab_id, values, setValues);
  const { openLoadSettingsDialog, closeLoadSettingsDialog } = useSavedSettings(parent, controls);

  const transfrom_sent_settings = (settings) => {
    if (!('_date_changed' in settings) || !settings['_date_changed']) {
      settings['time_cont_year'] = 0xffff;
      settings['time_cont_month'] = 0xff;
      settings['time_cont_day'] = 0xff;
    }
    if (!('_time_changed' in settings) || !settings['_time_changed']) {
      settings['time_cont_hour'] = 0xffff;
      settings['time_cont_min'] = 0xff;
      settings['time_cont_sec'] = 0xff;
    }
    return settings;
  };

  controls.restoreTempSettings = () => {
    let _values = _.merge(
      _.cloneDeep(parent.settings[tab_id]),
      _.cloneDeep(parent.temp_settings[tab_id])
    );
    for (const [k, v] of Object.entries(_values)) {
      if (v === '_deleted_') {
        delete _values[k];
      }
    }
    setValues(_values);
  };

  const handleIntInput = (name, value) => {
    let _values = { ...values };
    let v = Number(value);
    _values[name] = v;
    controls.saveTempSettings(name, v);

    setValues(_values);
  };

  const handleDateTimeInput = (name, format, value) => {
    let _values = { ...values };
    let fields;

    if (format === 'date') {
      let split = value.split('-');
      fields = [
        ['year', split[0]],
        ['month', split[1]],
        ['day', split[2]]
      ];
    } else {
      fields = [
        ['hour', value.getHours()],
        ['min', value.getMinutes()]
      ];

      // TODO: remove this block when we switched to a more uniform naming of the timer keys
      if (name.includes('time_interval_2')) {
        fields = [
          ['hour', value.getHours()],
          ['minute', value.getMinutes()]
        ];
      }

      if (format === 'time_sec') {
        fields.push(['sec', value.getSeconds()]);
      }
    }

    for (let field of fields) {
      let v = Number(field[1]);
      _values[`${name}_${field[0]}`] = v;
      controls.saveTempSettings(`${name}_${field[0]}`, v);
    }

    // set whether controller date or time was changed
    if (name === 'time_cont' && format === 'date') {
      _values['_date_changed'] = true;
      controls.saveTempSettings('_date_changed', true);
    }
    if (name === 'time_cont' && format === 'time_sec') {
      _values['_time_changed'] = true;
      controls.saveTempSettings('_time_changed', true);
    }

    setValues(_values);
  };

  /*
   * pack single values for hour, min, sec into one time string
   */
  function makeTime(prefix, seconds = false) {
    let str = `${addZ(values[`${prefix}_hour`])}:${addZ(values[`${prefix}_min`])}`;

    // TODO: remove this block when we switched to a more uniform naming of the timer keys
    if (prefix.includes('time_interval_2')) {
      str = `${addZ(values[`${prefix}_hour`])}:${addZ(values[`${prefix}_minute`])}`;
    }

    if (seconds) {
      str += `:${addZ(values[`${prefix}_sec`])}`;
    }

    return str;
  }

  /*
   * Methods for manipulating dynamic event/interval timers (industrial only)
   */
  let event_timer_ids = controller.type >= 6000 ? getEventTimerIds(values) : null;
  let interval_timer_ids = controller.type >= 6000 ? getIntervalTimerIds(values) : null;
  let static_event_timers = [5055, 5155].includes(controller.type)
    ? [1, 2, 3, 4, 5, 6, 7, 8]
    : [1, 2, 3, 4];
  let static_interval_timers = [5055, 5155].includes(controller.type)
    ? ['interval', 'time_interval_2']
    : ['interval'];

  const addCycle = function (et) {
    let _values = { ...values };
    let c = _values[`event${et}_cycles`];

    for (const [k, v] of [
      [`event${et}_cycles`, c + 1],
      [`event${et}_start${c}_hour`, 0],
      [`event${et}_start${c}_min`, 5],
      [`event${et}_duration${c}_hour`, 0],
      [`event${et}_duration${c}_min`, 0],
      [`event${et}_duration${c}_sec`, 5]
    ]) {
      _values[k] = v;
      controls.saveTempSettings(k, v);
    }

    setValues(_values);
  };

  const removeCycle = function (et) {
    let _values = { ...values };
    let c = _values[`event${et}_cycles`];
    _values[`event${et}_cycles`] = c - 1;
    controls.saveTempSettings(`event${et}_cycles`, c - 1);

    for (const [k, v] of [
      `event${et}_cycles`,
      `event${et}_start${c}_hour`,
      `event${et}_start${c}_min`,
      `event${et}_duration${c}_hour`,
      `event${et}_duration${c}_min`,
      `event${et}_duration${c}_sec`
    ]) {
      delete _values[k];
      controls.saveTempSettings(k, '_deleted_');
    }

    setValues(_values);
  };

  const addEventTimer = function () {
    let _values = { ...values };
    // get latest timer id
    let _event_timer_ids = _.cloneDeep(event_timer_ids).map((id) => Number(id));
    _event_timer_ids.sort((a, b) => a - b);
    let et = _event_timer_ids[_event_timer_ids.length - 1] + 1;

    for (const [k, v] of [
      [`event${et}_cycles`, 1],
      [`event${et}_start0_hour`, 0],
      [`event${et}_start0_min`, 0],
      [`event${et}_duration0_hour`, 0],
      [`event${et}_duration0_min`, 0],
      [`event${et}_duration0_sec`, 5],
      [`time_event${et}_exec_gap`, 0]
    ]) {
      _values[k] = v;
      controls.saveTempSettings(k, v);
    }

    setValues(_values);
  };

  const removeEventTimer = function (id) {
    let _values = { ...values };

    for (let c = 0; c < _values[`event${id}_cycles`]; c++) {
      for (const [key, subitems] of [
        ['start', ['hour', 'min']],
        ['duration', ['hour', 'min', 'sec']]
      ]) {
        for (let si of subitems) {
          delete _values[`event${id}_${key}${c}_${si}`];
          controls.saveTempSettings(`event${id}_${key}${c}_${si}`, '_deleted_');
        }
      }
    }

    for (let key of [`time_event${id}_exec_gap`, `event${id}_cycles`]) {
      delete _values[key];
      controls.saveTempSettings(key, '_deleted_');
    }

    setValues(_values);
  };

  const addIntervalTimer = function () {
    let _values = { ...values };
    // get latest timer id
    let _interval_timer_ids = interval_timer_ids.map((id) => Number(id));
    _interval_timer_ids.sort((a, b) => a - b);
    let et = _interval_timer_ids[_interval_timer_ids.length - 1] + 1;

    for (const [k, v] of [
      [`interval_type_${et}`, 0],
      [`interval_on_${et}_hour`, 0],
      [`interval_on_${et}_min`, 0],
      [`interval_on_${et}_sec`, 10],
      [`interval_off_${et}_hour`, 0],
      [`interval_off_${et}_min`, 0],
      [`interval_off_${et}_sec`, 10]
    ]) {
      _values[k] = v;
      controls.saveTempSettings(k, v);
    }

    setValues(_values);
  };

  const removeIntervalTimer = function (id) {
    let _values = { ...values };

    for (let key of [
      `interval_on_${id}_hour`,
      `interval_on_${id}_min`,
      `interval_on_${id}_sec`,
      `interval_off_${id}_hour`,
      `interval_off_${id}_min`,
      `interval_off_${id}_sec`,
      `interval_type_${id}`
    ]) {
      delete _values[key];
      controls.saveTempSettings(key, '_deleted_');
    }

    setValues(_values);
  };

  return (
    <React.Fragment>
      <LoadSettingsDialog
        open={openLoadSettingsDialog}
        handleClose={closeLoadSettingsDialog}
        controls={controls}
      />

      <SettingActions
        onReset={controls.resetSettings}
        onSave={() => controls.saveSettings(transfrom_sent_settings)}
      />

      <SettingGroup controller={controller} id={t('controller_time')}>
        <ValueBox controller={controller} id={'timing_controller_date'}>
          <Typography gutterBottom>
            <Desc id="controller_date" name="Controller Date" />
          </Typography>
          <DatePicker
            value={`${values.time_cont_year}-${addZ(values.time_cont_month)}-${addZ(values.time_cont_day)}`}
            name="controller_date"
            onChange={(event, value) => handleDateTimeInput('time_cont', 'date', value)}
          />
        </ValueBox>
        <ValueBox controller={controller} id={'timing_controller_time'}>
          <Typography gutterBottom>
            <Desc id="controller_time" name="Controller Time" />
          </Typography>
          <MyTimePicker
            value={makeTime('time_cont', true)}
            seconds={true}
            name="controller_time"
            id="controller_time"
            onChange={(value) => handleDateTimeInput('time_cont', 'time_sec', value)}
          />
        </ValueBox>
      </SettingGroup>

      <SettingGroup controller={controller} id={t('start_end_time')}>
        <ValueBox controller={controller} id={'timing_start_day'}>
          <Typography gutterBottom>
            <Desc id="start_day" name="Start Day at" />
          </Typography>
          <MyTimePicker
            value={makeTime('start_day')}
            seconds={false}
            name="start_day"
            onChange={(value) => handleDateTimeInput('start_day', 'time_min', value)}
          />
        </ValueBox>
        <ValueBox controller={controller} id={'timing_end_day'}>
          <Typography gutterBottom>
            <Desc id="end_day" name="End Day at" />
          </Typography>
          <MyTimePicker
            value={makeTime('end_day')}
            seconds={false}
            name="end_day"
            onChange={(value) => handleDateTimeInput('end_day', 'time_min', value)}
          />
        </ValueBox>
      </SettingGroup>

      {/* ------------ dynamic interval timer (industrial) --------------*/}
      {controller.type >= 6000 ? (
        <>
          {interval_timer_ids.map((e) => (
            <SettingGroup label={`${t('interval')} ${e}`}>
              <ValueBox controller={controller} id={'timing_interval_on'}>
                <Typography gutterBottom>
                  <Desc id="interval_on" name="Interval On" />
                </Typography>
                <MyTimePicker
                  value={makeTime(`interval_on_${e}`, true)}
                  seconds={true}
                  name="interval_on"
                  onChange={(value) => handleDateTimeInput(`interval_on_${e}`, 'time_sec', value)}
                />
              </ValueBox>
              <ValueBox>
                <Typography gutterBottom>
                  <Desc id="interval_off" name="Interval Off" />
                </Typography>
                <MyTimePicker
                  value={makeTime(`interval_off_${e}`, true)}
                  seconds={true}
                  name="interval_off"
                  onChange={(value) => handleDateTimeInput(`interval_off_${e}`, 'time_sec', value)}
                />
              </ValueBox>
              <ValueBox>
                <Typography gutterBottom>
                  <Desc id="interval_phase" name="Phase" />
                </Typography>
                <Select
                  value={values[`interval_type_${e}`]}
                  onChange={(evt) => {
                    let value = evt.target.value;
                    let _values = { ...values };
                    _values[`interval_type_${e}`] = value;
                    setValues(_values);
                    controls.saveTempSettings(`interval_type_${e}`, value);
                  }}
                >
                  <MenuItem value={0} selected={true}>
                    {t('Day')}
                  </MenuItem>
                  <MenuItem value={1}>{t('Night')}</MenuItem>
                  <MenuItem value={2}>{t('Day + Night')}</MenuItem>
                </Select>
              </ValueBox>

              <Button
                startIcon={<DeleteIcon />}
                color="secondary"
                onClick={() => removeIntervalTimer(e)}
              >
                {t('remove_interval_timer')}
              </Button>
            </SettingGroup>
          ))}
          <Button startIcon={<AddIcon />} color="primary" onClick={() => addIntervalTimer()}>
            {t('add_interval_timer')}
          </Button>
        </>
      ) : (
        <>
          {/* ------------ static interval timer (GrowBase) --------------*/}
          {static_interval_timers.map((e) => (
            <>
              {['day', 'night'].map((phase) => (
                <SettingGroup controller={controller} id={`${e}_${phase}`}>
                  {['on', 'off'].map((type) => (
                    <ValueBox>
                      <Typography gutterBottom>
                        <Desc id={`${e}_${type}_${phase}`} show={`${e}_${type}_${phase}`} />
                      </Typography>
                      <MyTimePicker
                        value={makeTime(`${e}_${type}_${phase}`, true)}
                        seconds={true}
                        name={`${e}_${type}_${phase}`}
                        onChange={(value) =>
                          handleDateTimeInput(`${e}_${type}_${phase}`, 'time_sec', value)
                        }
                      />
                    </ValueBox>
                  ))}
                </SettingGroup>
              ))}
            </>
          ))}
        </>
      )}

      {controller.type >= 6000 ? (
        <>
          {/* ------------ dynamic event timer (industrial) --------------*/}
          {event_timer_ids.map((e) => (
            <SettingGroup label={`${t('event')} ${e}`}>
              {[...Array(values[`event${e}_cycles`]).keys()].map((cycle) => (
                <Box>
                  <ValueBox>
                    <Typography gutterBottom>
                      <Desc id={`event${e}_start${cycle}`} show="event_start" />
                    </Typography>
                    <MyTimePicker
                      value={makeTime(`event${e}_start${cycle}`)}
                      seconds={false}
                      name={`event${e}_start${cycle}`}
                      onChange={(value) =>
                        handleDateTimeInput(`event${e}_start${cycle}`, 'time_min', value)
                      }
                    />
                  </ValueBox>
                  <ValueBox>
                    <Typography gutterBottom>
                      <Desc id={`event${e}_duration${cycle}`} show="event_duration" />
                    </Typography>
                    <MyTimePicker
                      value={makeTime(`event${e}_duration${cycle}`, true)}
                      seconds={true}
                      name={`event${e}_duration${cycle}`}
                      onChange={(value) =>
                        handleDateTimeInput(`event${e}_duration${cycle}`, 'time_sec', value)
                      }
                    />
                  </ValueBox>
                </Box>
              ))}
              <Box style={{ display: 'flex', flexDirection: 'column' }}>
                <Button startIcon={<AddIcon />} onClick={() => addCycle(e)}>
                  {t('add_cycle')}
                </Button>
                {values[`event${e}_cycles`] > 1 && (
                  <Button startIcon={<DeleteIcon />} onClick={() => removeCycle(e)}>
                    {t('remove_cycle')}
                  </Button>
                )}
              </Box>
              <ValueBox style={{ maxWidth: 'fit-content' }}>
                <Typography gutterBottom>
                  <Desc id={`time_event${e}_exec_gap`} show="event_exec_gap" />
                </Typography>
                <EventGapSelect
                  value={values[`time_event${e}_exec_gap`]}
                  onChange={(event, value) =>
                    handleIntInput(`time_event${e}_exec_gap`, event.target.value)
                  }
                />
              </ValueBox>
              <Button
                startIcon={<DeleteIcon />}
                color="secondary"
                onClick={() => removeEventTimer(e)}
              >
                {t('remove_event_timer')}
              </Button>
            </SettingGroup>
          ))}

          <Button startIcon={<AddIcon />} color="primary" onClick={() => addEventTimer()}>
            {t('add_event_timer')}
          </Button>
        </>
      ) : (
        <>
          {/* ------------ static event timer (growbase) --------------*/}
          {static_event_timers.map((e) => (
            <SettingGroup label={t(`event_${e}`)}>
              <ValueBox>
                <Typography gutterBottom>
                  <Desc id={`event${e}_start`} show="event_start" />
                </Typography>
                <MyTimePicker
                  value={makeTime(`event${e}_start`)}
                  seconds={false}
                  name={`event${e}_start`}
                  onChange={(value) => handleDateTimeInput(`event${e}_start`, 'time_min', value)}
                />
              </ValueBox>
              <ValueBox>
                <Typography gutterBottom>
                  <Desc id={`event${e}_duration`} show="event_duration" />
                </Typography>
                <MyTimePicker
                  value={makeTime(`event${e}_duration`, true)}
                  seconds={true}
                  name={`event${e}_duration`}
                  onChange={(value) => handleDateTimeInput(`event${e}_duration`, 'time_sec', value)}
                />
              </ValueBox>
              <ValueBox>
                <Typography gutterBottom>
                  <Desc id={`time_event${e}_exec_gap`} show="event_exec_gap" />
                </Typography>
                <EventGapSelect
                  value={values[`time_event${e}_exec_gap`]}
                  onChange={(event, value) =>
                    handleIntInput(`time_event${e}_exec_gap`, event.target.value)
                  }
                />
              </ValueBox>
            </SettingGroup>
          ))}
        </>
      )}

      <SettingActions
        onReset={controls.resetSettings}
        onSave={() => controls.saveSettings(transfrom_sent_settings)}
      />
    </React.Fragment>
  );
}
