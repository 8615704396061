import React, { Fragment, useState } from 'react';
import getStateManager from '../StateManager.js';
import { Switch, Route, useParams, useRouteMatch, Link, Redirect } from 'react-router-dom';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { AppBar, makeStyles, Tab, Tabs } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import SettingsIcon from '@material-ui/icons/Settings';
import DevicesIcon from '@material-ui/icons/Devices';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import { SettingsTabs } from '../Pages/ControllerPage/SettingsTabs.js';
import SystemSettings from '../Tabs/SystemSettings.js';
import Slaves from '../Tabs/Industrial/Slaves.js';

export default function ZoneMenu() {
  let { id, menu = 'zones', zone_id = 'zone_0', tab_id = 'info' } = useParams();
  const stateManager = getStateManager();
  const controller = stateManager.getControllerById(id);

  let { path, url } = useRouteMatch();
  let { t } = useTranslation();

  const [currCat, setCurrCat] = useState(menu);
  const [currZoneTab, setCurrZoneTab] = useState(zone_id);

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [renameDialog, setRenameDialog] = useState(null); // null => closed, otherwise set to zone object
  const [deleteDialog, setDeleteDialog] = useState(null); // null => closed, otherwise set to zone object
  const [initialName, setInitialName] = useState('');

  const useStyles = makeStyles((theme) => ({
    withDivider: {
      borderRight: `1px solid ${theme.palette.text.primary}`,
      marginRight: '10px'
    }
  }));

  const classes = useStyles();
  console.log('zonemenu', controller.zones);
  return (
    <>
      {/* !zone_id && <Redirect to={`${url}/zones/${Object.values (controller?.zones ?? {})[0]?.name ?? 'default'}/info`} /> */}

      <TabContext value={currCat !== 'zones' ? currCat : currZoneTab}>
        <AppBar position="static" color="default">
          <TabList
            variant="scrollable"
            scrollButtons="auto"
            onChange={(e, v) => (currCat !== 'zones' ? setCurrCat(v) : setCurrZoneTab(v))}
          >
            {/*<Tab
							value="slaves"
							label={<div style={{display: 'flex', alignItems: 'center'}}><DevicesIcon style={{marginRight: '10px'}} /> {t("slaves")}</div>}
							component={Link}
							to={`/controller/${id}/slaves`}
							key={`slaves-tab`}
							id={`slaves-tab`}
							aria-controls={`slaves-tabpanel`}
						/>
						<Tab
							value="manage_zones"
							label={<div style={{display: 'flex', alignItems: 'center'}}><AddIcon /> {t("manage_zones")}</div>}
							component={Link}
							to={`/controller/${id}/manage_zones`}
							key={`manage_zones-tab`}
							id={`manage_zones-tab`}
							aria-controls={`manage_zones-tabpanel`}
						/>
						<Tab
							value="system"
							label={<div style={{display: 'flex', alignItems: 'center'}}><SettingsIcon /> {t("system")}</div>}
							className={classes.withDivider}
							component={Link}
							to={`/controller/${id}/system`}
							key={`system-tab`}
							id={`system-tab`}
							aria-controls={`system-tabpanel`}
						/>*/}
            {Object.values(controller.zones).map((zone, index) => (
              <Tab
                value={'zone_' + zone.id}
                label={zone.name}
                component={Link}
                to={`/controller/${id}/zones/zone_${zone.id}/${tab_id ?? 'info'}`}
                key={`zone-tab-${index}`}
                id={`zone-tab-${index}`}
                aria-controls={`zone-tabpanel-${index}`}
              />
            ))}
          </TabList>
        </AppBar>

        <Route path={`/controller/:id/slaves`}>
          <TabPanel value="slaves">
            <Container>
              <Paper style={{ padding: '2em', margin: '2em' }}>
                <Slaves />
              </Paper>
            </Container>
          </TabPanel>
        </Route>
        <Route path={`/controller/:id/system`}>
          <TabPanel value="system">
            <SystemSettings controller={controller} />
          </TabPanel>
        </Route>
        <Route path={`/controller/:id/manage_zones`}>
          <TabPanel value="manage_zones">
            <Container>
              <TableContainer component={Paper}>
                <Table aria-label="table of zones">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.values(controller.zones).map((zone) => (
                      <TableRow key={zone.id}>
                        <TableCell>{zone.name}</TableCell>
                        <TableCell>
                          <Button
                            onClick={() => {
                              setInitialName(zone.name);
                              setRenameDialog(zone);
                            }}
                          >
                            <EditIcon /> {t('rename')}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => setDeleteDialog(zone)}
                            color="secondary"
                            disabled={Object.values(controller.zones).length <= 1}
                          >
                            <DeleteIcon /> {t('delete')}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button onClick={() => setOpenAddDialog(true)}>
                <AddIcon /> {t('add_zone')}
              </Button>
            </Container>

            <AddDialog
              open={openAddDialog}
              controller={controller}
              wsManager={stateManager.wsManager}
              handleClose={() => setOpenAddDialog(false)}
            />
            <RenameDialog
              zone={renameDialog}
              name={initialName}
              setName={setInitialName}
              controller={controller}
              wsManager={stateManager.wsManager}
              handleClose={() => setRenameDialog(null)}
            />
            <DeleteDialog
              zone={deleteDialog}
              controller={controller}
              wsManager={stateManager.wsManager}
              handleClose={() => setDeleteDialog(null)}
            />
          </TabPanel>
        </Route>

        {Object.values(controller.zones).map((zone, index) => (
          <TabPanel value={'zone_' + zone.id}>
            <SettingsTabs
              path={`/controller/:id/:zones(zones)?/:zone_id?`}
              url={`/controller/${id}/zones/${zone_id ?? 0}`}
              controller={controller}
              parent={controller.zones[index]}
            />
          </TabPanel>
        ))}
      </TabContext>
    </>
  );
}

function AddDialog({ open, handleClose, controller, wsManager }) {
  let { t } = useTranslation();
  const [newZoneName, setNewZoneName] = useState('');
  const action = () => {
    wsManager.sendWSMsg(
      controller,
      'add_zone',
      {
        name: newZoneName
      },
      true
    );
    setNewZoneName('');
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-add">
      <DialogTitle id="form-dialog-add">{t('add_zone')}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          onChange={(evt) => setNewZoneName(evt.target.value)}
          value={newZoneName}
          id="zone_name"
          label={t('name')}
          type="text"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={action} style={{ marginTop: '1em' }} variant="contained" type="button">
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function RenameDialog({ zone, name, setName, handleClose, controller, wsManager }) {
  let { t } = useTranslation();
  const [value, setValue] = React.useState(zone?.name);
  const action = () => {
    wsManager.sendWSMsg(controller, 'rename_zone', {
      id: zone.id,
      name: name
    });
    handleClose();
  };

  return (
    <Dialog open={zone != null} onClose={handleClose} aria-labelledby="form-dialog-rename">
      <DialogTitle id="form-dialog-rename">{t('rename')}</DialogTitle>
      <DialogContent>
        <TextField
          value={name}
          autoFocus
          margin="dense"
          id="rename"
          label="Name"
          type="text"
          fullWidth
          onChange={(event) => setName(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={action} color="primary">
          {t('rename')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function DeleteDialog({ zone, handleClose, controller, wsManager }) {
  let { t } = useTranslation();
  const [value, setValue] = React.useState(zone?.name);
  const action = () => {
    wsManager.sendWSMsg(controller, 'delete_zone', {
      id: zone.id
    });
    handleClose();
  };

  return (
    <Dialog open={zone != null} onClose={handleClose} aria-labelledby="form-dialog-delete">
      <DialogTitle id="form-dialog-delete">{t('delete')}</DialogTitle>
      <DialogContent>{t('confirm_delete')}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={action} color="primary">
          {t('yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
