import Info from '../../Tabs/Info.js';
import AdvancedSettings from '../../Tabs/AdvancedSettings.js';
import ClimateSettings from '../../Tabs/ClimateSettings.js';
import Co2Settings from '../../Tabs/Co2Settings.js';
import LightsSettings from '../../Tabs/LightsSettings.js';
import SystemSettings from '../../Tabs/SystemSettings.js';
import TimingSettings from '../../Tabs/TimingSettings.js';
import VentilationSettings from '../../Tabs/VentilationSettings.js';
import MultiOutSettings from '../../Tabs/MultiOutSettings.js';
import AlarmSettings from '../../Tabs/AlarmSettings.js';
import Scheduling from '../../Tabs/Scheduling.js';
import { MemorizedGraph } from '../../Tabs/Graph';
import NutrientSettings from '../../Tabs/NutrientSettings.js';
import IrrigationSettings from '../../Tabs/IrrigationSettings';

export const useConfigObject = (controller, parent) => {
  const tabs = [
    {
      id: 'info',
      label: 'Info',
      content: <Info controller={controller} parent={parent} />
    },
    {
      id: 'graph',
      label: 'Graph',
      content: <MemorizedGraph controller={controller} parent={parent} />
    },
    {
      id: 'climate',
      label: 'Climate',
      content: <ClimateSettings controller={controller} parent={parent} />
    },
    {
      id: 'co2',
      label: 'C02',
      content: <Co2Settings controller={controller} parent={parent} />
    },
    {
      id: 'ventilation',
      label: 'Ventilation',
      content: <VentilationSettings controller={controller} parent={parent} />
    },
    {
      id: 'timing',
      label: 'Timing',
      content: <TimingSettings controller={controller} parent={parent} />
    },
    {
      id: 'lights',
      label: 'Lights',
      content: <LightsSettings controller={controller} parent={parent} />
    },
    {
      id:
        controller.type === 6000 || (controller.type === 5155 && parent.settings.multi_io != null)
          ? 'multi_in_out'
          : 'outputs',
      label:
        controller.type === 6000 || (controller.type === 5155 && parent.settings.multi_io != null)
          ? 'Multi I/O'
          : 'Outputs',
      content: <MultiOutSettings controller={controller} parent={parent} />
    },
    {
      id: 'alarms',
      label: 'Alarms',
      content: <AlarmSettings controller={controller} parent={parent} />
    },
    {
      id: 'advanced',
      label: 'Advanced',
      content: <AdvancedSettings controller={controller} parent={parent} />
    },
    {
      id: 'scheduling',
      label: 'Scheduling',
      content: <Scheduling controller={controller} parent={parent} />
    },
    {
      id: 'nutrition',
      label: 'Nutrition',
      content: <NutrientSettings controller={controller} parent={parent} />
    },
    {
      id: 'irrigation',
      label: 'Irrigation',
      content: <IrrigationSettings controller={controller} parent={parent} />
    }
  ];

  // does not support multi-zone
  if (controller.type !== 6000) {
    tabs.splice(6, 0, {
      id: 'system',
      label: 'System',
      content: <SystemSettings controller={controller} />
    });
  }

  return tabs;
};
