import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { Line } from 'react-chartjs-2';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { useTranslation } from 'react-i18next';
import { SettingGroup, ValueBox } from '../../FormWidgets/SettingGroup';
import { CircularIndeterminate } from '../../FormWidgets/Others';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { statusColors } from '../../Constants/constants';

import { GenericSelect } from '../../FormWidgets/Select.js';

import getStateManager from '../../StateManager.js';

const useStyles = makeStyles((theme) => ({
  slave: {
    display: 'block',
    border: '0.3em solid black',
    margin: '1em',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '1em',
    boxShadow: '0.5em 0.5em 2em 0.1em #00000'
  },

  label: {
    margin: '1em',
    fontWeight: 'normal',
    color: 'black'
  },

  display: {
    display: 'block',
    padding: '1em',
    margin: '1em 0 1em 0',
    backgroundColor: '#1B1B1B',
    color: 'white'
  },

  lcd: {
    display: 'block',
    backgroundColor: 'blue',
    padding: '1em',
    fontFamily: 'Courier New',
    fontStyle: 'normal',
    border: '0.1em solid #B5B5FF'
  },

  sensors_out_ports: {
    display: 'block',
    padding: '1em',
    margin: '1em 0 1em 0'
  },

  block: {
    display: 'block'
  },

  connection: {
    height: '1.1em',
    width: '1.1em',
    borderRadius: '50%',
    display: 'inline-block',
    margin: '1em'
  }
}));

function getCommonZone(ports, zones) {
  if (ports.length === 0 || zones.length === 0) {
    return 'n/a';
  }

  for (let zone of Object.keys(zones)) {
    let found = true;
    for (let port of ports) {
      if (port.zone !== zone) {
        found = false;
        break;
      }
    }

    if (found) {
      return zone;
    }
  }

  return 'mixed';
}

function Slave({ slave, zones, stateManager, controller, setConfirmDialog }) {
  const classes = useStyles();
  const [showOutport, setShowOutport] = useState(false);

  let setSlaveZone = function (zone, ports = []) {
    //SendControllerWSMsg (wsRef.current, controllerId, "set_slave_zone", {slave: slave.address, zone: zone});

    let data = { slave: slave.address, zone: zone };
    if (ports.length > 0) data.ports = ports;
    stateManager.wsManager.sendWSMsg(controller, 'set_slave_zone', data, true);
  };

  let commonZone = slave.multi_outs ? getCommonZone(slave.multi_outs, zones) : null;
  return (
    <tr>
      <td>0x{slave.address.toString(16).toUpperCase()}</td>
      <td>{slave.abilities.join(',')}</td>
      <td>
        {
          <connection
            class={classes.connection}
            style={{ backgroundColor: statusColors[slave.connected ? 'good' : 'bad'] }}
          ></connection>
        }
      </td>
      <td>
        {slave.abilities.includes('sensor') && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {slave.abilities.includes('out') && (
              <div style={{ marginRight: '1em' }}>Assign sensor ports to zone</div>
            )}
            <GenericSelect
              onChange={(evt) => setSlaveZone(evt.target.value)}
              values={Object.values(zones).map((z) => ({ value: z.id, label: z.name }))}
              value={slave.zone}
            />
          </div>
        )}

        {slave.abilities.includes('out') && (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {slave.abilities.includes('sensor') && (
                <div style={{ marginRight: '1em' }}>Assign multi outs to zone</div>
              )}
              <GenericSelect
                onChange={(evt) => {
                  setConfirmDialog({
                    open: true,
                    apply: () =>
                      setSlaveZone(
                        evt.target.value,
                        slave.multi_outs.map((mo) => mo.id)
                      ),
                    close: () => setConfirmDialog({ open: false, apply: null, close: null })
                  });
                }}
                values={Object.values(zones)
                  .map((z) => ({ value: z.id, label: z.name }))
                  .concat(commonZone === 'mixed' ? [{ value: 'mixed', label: 'mixed' }] : [])}
                value={commonZone === 'mixed' ? 'mixed' : commonZone}
              />

              <div
                style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
                onClick={() => setShowOutport(!showOutport)}
              >
                {showOutport ? (
                  <>
                    <ExpandLessIcon /> Hide individual output ports
                  </>
                ) : (
                  <>
                    <ExpandMoreIcon /> Show individual output ports
                  </>
                )}
              </div>
            </div>
            {showOutport && (
              <>
                {slave.multi_outs.map((port, index) => (
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <span>Out {port.id}</span>

                    <GenericSelect
                      onChange={(evt) => setSlaveZone(evt.target.value, [port.id])}
                      values={Object.values(zones).map((z) => ({ value: z.id, label: z.name }))}
                      value={port.zone}
                    />
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </td>
    </tr>
  );
}

export default function Slaves() {
  let { id } = useParams();
  let { t } = useTranslation();
  let time_points = 180;

  const stateManager = getStateManager();
  const controller = stateManager.getControllerById(id);

  const [confirmDialog, setConfirmDialog] = useState({ open: false, apply: null, close: null });

  return (
    <>
      <ConfirmPortZoneDialog data={confirmDialog} />
      <table style={{ width: '100%' }}>
        <thead style={{ fontWeight: 'bold', fontSize: '1.3em' }}>
          <td>Adress</td>
          <td>Abilities</td>
          <td>Connected</td>
          <td>Zone</td>
        </thead>
        <tbody>
          {controller.slaves.map((slave) => (
            <Slave
              slave={slave}
              setConfirmDialog={setConfirmDialog}
              zones={controller.zones}
              stateManager={stateManager}
              controller={controller}
            />
          ))}
        </tbody>
      </table>
    </>
  );
}

function ConfirmPortZoneDialog({ data }) {
  let { t } = useTranslation();

  return (
    <Dialog open={data.open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('confirm-port-zone')}</DialogTitle>
      <DialogContent>
        <Typography> {t('confirm-port-zone')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            data.apply();
            data.close();
          }}
          color="primary"
        >
          {t('auto_save_ok')}
        </Button>
        <Button onClick={() => data.close()} color="primary">
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
