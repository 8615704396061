import {
  CreateControllerWSRequest,
  CreateGlobalWSRequest,
  BinaryToUintArray,
  Interval
} from '../Utils/utils.js';

import globalOnMessage from './globalOnMessageHook.js';

export function createWebsocketManager(wasm) {
  return {
    socket: null,
    status: 'off',
    wasm: wasm,
    format: 'json', // or 'msgpack'

    init(store = null) {
      if (store) this.store = store;
      this.address = store.address;

      this.socket = new WebSocket(this.address);
      this.socket.binaryType = 'arrayBuffer';

      this.socket.onopen = () => {
        this.sendWSMsg(null, 'initialize_user');

        // in case of failed initialization the server does not respond at all.
        // catch this case here
        setTimeout(() => {
          if (this.status === 'off') {
            console.log('[wsManager] failed init');
            this.fail();
          }
        }, 3000); // 3 sec to wait for an answer
      };

      this.socket.onclose = () => {
        if (this.status === 'on') {
          console.log('[wsManager] socket closed unexpected');
          this.fail();
        }
      };

      this.socket.onerror = () => {
        console.log('[wsManager] socket error');
        this.fail();
      };

      this.socket.onmessage = (event) => {
        let data;

        if (this.format === 'msgpack') {
          try {
            const reader = new FileReader();
            reader.onload = () => {
              const binary_str = reader.result;
              const len = binary_str.length;
              const new_data = wasm.fromBinary(BinaryToUintArray(binary_str, len), len);

              data = JSON.parse(new_data);
            };
            reader.readAsBinaryString(event.data);
          } catch (e) {
            console.log('[global] Failed parsing json: ', event.data, e);
          }
        } else {
          data = JSON.parse(event.data);
        }

        console.log('[wsManager] onmessage', data);
        globalOnMessage(this, data);
      };
    },

    // setup request loops
    start: function () {
      this.controllers_active = new Interval(
        () => this.sendWSMsg(null, 'controllers_active'),
        2500
      );
      this.controllers_active.run();

      this.request_values = new Interval(() => {
        const controllers = this.store.controllers;
        for (let controller of controllers) {
          if ((controller?.connection?.status ?? 'bad') !== 'bad') {
            if (!controller.connection.request_values_full) {
              this.sendWSMsg(controller, 'request_values_full');
            } else {
              this.sendWSMsg(controller, 'request_values');
            }
          }
        }
      }, 1000);

      this.request_values.run();
    },

    sendWSMsg: function (controller, command, data = {}, forward = false) {
      if (this.socket.readyState !== 1 || controller?.connection?.status === 'bad') return;

      const req =
        controller != null
          ? CreateControllerWSRequest(this.socket, controller.id, command, data)
          : CreateGlobalWSRequest(this.socket, command, data);

      // Add cookie
      req.cookie = document.cookie;

      if (forward) {
        req.forward = true;
      }

      let msg = JSON.stringify(req);

      if (req) {
        if (this.format === 'msgpack' && this.wasm.toBinary) {
          const binary_str = this.wasm.toBinary(msg);
          let binaryData = new Uint8Array(
            atob(binary_str)
              .split('')
              .map(function (c) {
                return c.charCodeAt(0);
              })
          );
          msg = binaryData;
        }

        this.socket.send(msg);
        if (req.command !== 'request_values') console.log('[ws send]', req.command + ':', msg);
      }
    },

    stop: function () {
      this.controllers_active.stop();
      this.request_values.stop();
      this.status = 'off';
      this.socket.close();
    },

    fail: function () {
      this.store.hooks.setNoConnection(true);
      this.stop();
    }
  };
}
