import React, { Fragment } from 'react';
import { Route, useParams } from 'react-router-dom';
import _ from 'lodash';
import { CircularIndeterminate } from '../FormWidgets/Others';
import { SettingsTabs } from './ControllerPage/SettingsTabs.js';
import ZoneMenu from '../Menus/ZoneMenu.js';

import getStateManager from '../StateManager.js';
import { useSnapshot } from 'valtio';

export default function ControllerPage({ ...other }) {
  let { id } = useParams();

  const store = getStateManager();
  const stateManager = useSnapshot(store);
  const controller = stateManager.getControllerById(id);

  return (
    <Fragment>
      {!_.isEmpty(controller?.settings) || !_.isEmpty(controller?.zones) ? (
        <>
          {controller?.zones != null ? (
            <Route path={`/controller/:id/:menu?/:zone_id?/:tab_id?`}>
              <ZoneMenu controller={controller} />
            </Route>
          ) : (
            <Route path={`/controller/:id/:tab_id?`}>
              <SettingsTabs
                url={`/controller/${id}`}
                path={'/controller/:id'}
                parent={controller}
                controller={controller}
              />
            </Route>
          )}
        </>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '3em' }}>
          <CircularIndeterminate />
        </div>
      )}
    </Fragment>
  );
}
