import { proxy, ref } from 'valtio';
import { createWebsocketManager } from './websocket/WebsocketManager.js';
import { createSimulation } from './websocket/Simulation.js';
import { initialHistory, initialFSStatus } from './Constants/InitialSettings.js';

import _ from 'lodash';

//import {info_values, info_io, climate, co2, ventilation, timing, lights, system, multi_outs, multi_ios, alarm, advanced} from './Constants/settings.js'
function stateManager() {
  return {
    controllers: [],
    simulation: null,

    alarms: {
      activeAlarms: [],
      alarmsLog: [],
      unreadAlarms: false,
      openAlarms: false
    },
    messages: {
      messages: [],
      unreadMessages: false
    },

    init(wasm, address, hooks) {
      store.address = address.websocket;
      store.wasm = ref(wasm);
      store.hooks = hooks;
      store.wsManager = ref(createWebsocketManager(wasm));
      store.wsManager.init(store);
    },

    getControllerById: (id) => store.controllers.find((c) => c.id === id),

    getControllerByName: (name) => store.controllers.find((c) => c.name === name),

    addController: function (data, user) {
      let temp_settings = JSON.parse(localStorage.getItem(`${data.id}_temp_settings`) ?? 'null');
      let controller = {
        ...data,

        project_id: 1,
        settings: {},
        temp_settings: temp_settings ? _.cloneDeep(temp_settings) : {}, // hold settings which are not saved yet
        /* wrap history in `data` is needed to keep valtio's `subscribe`
				   working when the history is replaced. Happens in tabs/Graph.js */
        history: { data: _.cloneDeep(initialHistory) },
        infos: { humidity_graph: [], temp_graph: [] },
        fs_status: _.cloneDeep(initialFSStatus),
        connection: {
          initialized: false,
          requested_all: false,
          request_values_full: false,
          status: 'bad',
          last_received: 0
        }
      };
      // Update graph show/hide values from stored data.
      for (let [key, value] of Object.entries(data['graph_hidden'])) {
        controller.history.data.hidden[key] = value;
      }
      delete controller['graph_hidden'];

      if (controller.type === 6000) {
        // support zones?
        controller.zones = {};
        controller.slaves = [];
      }

      if (controller.simulation === true) {
        controller.simulation = createSimulation(store.address, controller, user, store.wasm);
        store.simulation = ref(controller.simulation);
      }

      store.controllers = store.controllers
        .filter((c) => c.id !== controller.id) // remove duplicates
        .concat(controller);
    },

    /*
     * @param parent: reference to controller or to zone
     */
    saveSettings(controller, parent, newSettings, tab_id, transform = null) {
      if (newSettings !== null) {
        if (controller.connection.status !== 'bad') {
          parent.settings[tab_id] = newSettings;
          const settings_data = {};
          settings_data[tab_id] = transform ? transform(_.cloneDeep(newSettings)) : newSettings;

          // multi zones
          let zoneable = controller.type === 6000;
          if (zoneable && tab_id !== 'system') {
            settings_data.zone = parent.id;
          }
          store.wsManager.sendWSMsg(controller, 'update_setpoints', settings_data, zoneable);
        }
      }
    },

    // return reference to the real store behind useSnapshot().
    // use this for manipulating states directly without a set-function
    store() {
      return store;
    }
  };
}

let store;

export default function getStateManager() {
  if (store == null) store = proxy(stateManager()); // makes proxy re-initializable, used for logout
  return store;
}

export function destroyState() {
  store = null;
}
