import React from 'react';

import InfoIcon from '@material-ui/icons/Info';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { help } from '../Constants/help';
import { makeStyles } from '@material-ui/core/styles';

const style = {
  marginRight: 1,
  cursor: 'pointer',
  size: 1
};

const useStyles = makeStyles((theme) => ({
  infoicon: {
    color: theme.palette.text.primary,
    opacity: 0.7
  }
}));

export default function Desc({ id, show, cont_type, ...other }) {
  const help = GetHelp(id, show, cont_type);
  return (
    <href>
      <DisplayName id={id} show={show} brief={help.brief} />
      <InfoButton content={help.content} img={help.img} brief={help.brief} />
    </href>
  );
}

export function GetHelp(id, show, cont_type) {
  const language = i18next.language !== 'keys' ? i18next.language : 'de';
  const use_help = cont_type in help && id in help[cont_type] ? help[cont_type] : help['default'];
  const cur_help = id in use_help ? use_help[id] : show in use_help ? use_help[show] : undefined;
  return {
    brief: cur_help !== undefined ? cur_help[language]['brief'] : '',
    content: cur_help !== undefined ? cur_help[language]['full'] : '',
    img: cur_help !== undefined ? cur_help[language]['image'] : ''
  };
}

export function DisplayName({ id, show, brief }) {
  let { t } = useTranslation();

  return t(show !== undefined ? show : id);
}

export function InfoButton({ content, img, brief }) {
  const [open, setOpen] = React.useState(false);
  const openDialog = (controller_id) => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };
  const classes = useStyles();

  return (
    <href title={brief}>
      {content !== '' && (
        <InfoIcon
          className={classes.infoicon}
          style={style}
          color="disabled"
          fontSize="small"
          onClick={openDialog}
        />
      )}
      <InfoDialog open={open} handleClose={closeDialog} content={content} img={img} />
    </href>
  );
}

function InfoDialog({ content, open, img, handleClose }) {
  let { t } = useTranslation();
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Info</DialogTitle>
      <DialogContent style={{ whiteSpace: 'pre-line' }}>{content}</DialogContent>
      {img !== '' && <img src={img} alt={img} />}
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
