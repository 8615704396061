import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import { ApplyDeleteSelect } from '../FormWidgets/Select.js';

export function LoadSettingsDialog({ open, handleClose, controls, info = null }) {
  let { t } = useTranslation();

  const [settings, setSettings] = React.useState({ apply: 1, remember: false });
  const handleInput = (name, event, value) => {
    const cur_settings = { ...settings };
    cur_settings[name] = value;
    setSettings({ ...cur_settings });
  };

  const apply = (event) => {
    if (settings.remember) localStorage.setItem('auto_load', settings.apply);
    if (settings.apply) {
      controls.restoreTempSettings();
    } else {
      controls.resetSettings();
    }

    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('auto_saved_settings')}</DialogTitle>
      <DialogContent>
        <Typography>
          {t('auto_save_apply')} {info}
        </Typography>
        <ApplyDeleteSelect
          value={settings.apply}
          onChange={(event, value) => handleInput('apply', event, event.target.value)}
        />
        <Typography></Typography>
        <Checkbox
          checked={settings.remember}
          onChange={(event, value) => handleInput('remember', event, value)}
        />
        {t('auto_save_remember')}
      </DialogContent>
      <DialogActions>
        <Button onClick={apply} color="primary">
          {t('auto_save_ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
