import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Desc from '../FormWidgets/Description';
import { Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ShowSetting } from '../FormWidgets/ShowSliderSetting';
import { SettingGroupGrid, ValueBox } from '../FormWidgets/SettingGroup';
import { LoadSettingsDialog } from '../Utils/load_settings';

import { settingControls, useSavedSettings, writeTempSettings } from './settingFunctions';
import { SettingActions } from '../components';
import { GetIndexedId, IndexedId } from '../Utils/utils';
import { useTranslation } from 'react-i18next';
import { FlexSelect } from '../FormWidgets/Select';
import { nutritionPumpTypeMapping, nutritionPumpUnitMapping } from '../Constants/constants';

import { ExpandMore } from '../Utils/Expand';
import { Grid } from '@material-ui/core';
import { EditableLabel } from '../FormWidgets/EditableLabel';

export default function NutrientSettings({ controller, parent, ...other }) {
  let { tab_id } = useParams();
  let { t } = useTranslation();
  const [values, setValues] = useState(parent.settings[tab_id]);
  const controls = settingControls(controller, parent, tab_id, values, setValues);
  const { openLoadSettingsDialog, closeLoadSettingsDialog } = useSavedSettings(parent, controls);

  // handle expand pumps
  const [expand, setExpand] = useState(Array.from({ length: values.pump_cnt_used }, (_, i) => false));
  const handleExpandClick = (i) => {
    let modifiedExpand = expand;
    modifiedExpand[i] = !expand[i];
    setExpand(modifiedExpand);
  };

  controls.saveTempSettings = (id, v) => {
    if (parent.temp_settings[tab_id] == null) {
      parent.temp_settings[tab_id] = { "pumps": [] };
    }
    console.log("[nut] cur temp_settings: ", parent.temp_settings[tab_id]);
    if (id.indexOf(':') > -1) {
      const [index, field_id] = GetIndexedId(id);
      if (parent.temp_settings[tab_id].pumps.length > index)
        parent.temp_settings[tab_id].pumps[index][field_id] = v;
      else 
        parent.temp_settings[tab_id].pumps.push({[field_id]: v});
    } else parent.temp_settings[tab_id][id] = v;
    writeTempSettings(parent);
  }

  controls.resetSetting = (id) => {
    if (id.indexOf(':') > -1) {
      const [index, field_id] = GetIndexedId(id);
      delete parent.temp_settings[tab_id]["pumps"][index][field_id];
      let updatedValues = values;
      updatedValues["pumps"][index][field_id] = parent.settings[tab_id]["pumps"][index][field_id];
      setValues(updatedValues);
    } else {
      delete parent.temp_settings[tab_id][id];
      setValues({ ...values, [id]: parent.settings[tab_id][id] });
      delete parent.temp_settings[tab_id][id];
    }
    writeTempSettings(parent);
  }

  controls.setValue = (id, v, saveTemp = true) => {
    if (id.indexOf(':') > -1) {
      const [index, field_id] = GetIndexedId(id);
      let updatedValues = values;
      updatedValues["pumps"][index][field_id] = v;
      setValues(updatedValues);
    } else setValues({ ...values, [id]: v });
    // turn this off in slider's onChange to improve performance
    if (saveTemp) controls.saveTempSettings(id, v);
  }

  return (
    <React.Fragment>
      <LoadSettingsDialog
        open={openLoadSettingsDialog}
        handleClose={closeLoadSettingsDialog}
        controls={controls}
      />

      <SettingActions onReset={controls.resetSettings} onSave={controls.saveSettings} />

      <SettingGroupGrid controller={controller} id={'nutrient_solution'}>
        <ShowSetting
          id="ec_setpoint"
          controller={controller}
          cur_value={values.ec_setpoint}
          controller_value={parent.settings.nutrition.ec_setpoint}
          controls={controls}
          type="float"
          num_digits={2}
        />
        <ShowSetting
          id="ph_setpoint"
          controller={controller}
          cur_value={values.ph_setpoint}
          controller_value={parent.settings.nutrition.ph_setpoint}
          controls={controls}
          type="float"
          num_digits={2}
        />
        <ShowSetting
          id="temp_setpoint"
          controller={controller}
          cur_value={values.temp_setpoint}
          controller_value={parent.settings.nutrition.temp_setpoint}
          controls={controls}
          type="float"
        />
      </SettingGroupGrid>

      { values.pumps.map((pump, i) => (
        <SettingGroupGrid label={t("pump") + " " + (i+1)} fullWidth={true}>
          <Grid container>
            <Grid item xs={4}>
              <EditableLabel
                label={("my_memo")}
                id={IndexedId(i, "name")}
                value={values.pumps[i].name??-1}
                handleInput={(_, event, value) =>
                  controls.setValue(IndexedId(i, 'name'), value)
                }
                no_label={true}
              />
            </Grid>
            <Grid item xs={4}>
              { t(nutritionPumpTypeMapping[values.pumps[i].type]) }
            </Grid>
          </Grid>

          <div style={{ textAlign: 'center', marginTop: '-5em' }}>
            <ExpandMore
              expand={expand[i]}
              onClick={() => handleExpandClick(i)}
              aria-expanded={expand[i]}
              aria-label="show more"
            >
              <ExpandMoreIcon fontSize='large' />
            </ExpandMore>
          </div>

          <Collapse in={expand[i]} timeout="auto" unmountOnExit>
            <Grid container spacing={1} columns={{ xs: 6, md: 12 }}>
              <Grid item xs={12} md={6}>
                <ShowSetting
                  id={IndexedId(i, "value")}
                  show="pump_dosage"
                  controller={controller}
                  cur_value={pump.value}
                  controller_value={parent.settings.nutrition.pumps[i].value}
                  controls={controls}
                  unit={nutritionPumpUnitMapping[values.pumps[i].unit]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ValueBox controller={controller} id={'type'}>
                  <Typography gutterBottom>
                    <Desc id="pump_type" />
                  </Typography>
                  <FlexSelect
                    id={IndexedId(i, "type")} 
                    controller={controller} 
                    value={pump.type} 
                    controller_value={parent.settings.nutrition.pumps[i].type}
                    controls={controls}
                    mapping={nutritionPumpTypeMapping}
                    onChange={(event) =>
                      controls.setValue(IndexedId(i, "type"), event.target.value)
                    }
                  />
                </ValueBox>
                <ValueBox controller={controller} id={'lights_dim_over_temp_enable'}>
                  <Typography gutterBottom>
                    <Desc id="pump_unit" />
                  </Typography>
                  <FlexSelect
                    id={IndexedId(i, "unit")} 
                    controller={controller} 
                    value={pump.unit} 
                    controller_value={parent.settings.nutrition.pumps[i].unit}
                    controls={controls}
                    mapping={nutritionPumpUnitMapping}
                    onChange={(event) =>
                      controls.setValue(IndexedId(i, "unit"), event.target.value)
                    }
                  />
                </ValueBox>
              </Grid>
            </Grid>
          </Collapse>
        </SettingGroupGrid>
      ))}

      <SettingActions onReset={controls.resetSettings} onSave={controls.saveSettings} />
    </React.Fragment>
  );
}
